import React, { FC, useState } from 'react';
import { MotifButton } from '../assets/lib/motif';
import Container from 'assets/styled/Container';
import DatePicker from 'components/base/DatePicker';
import { Modal } from 'components/base';

const Test: FC = () => {
    const [value, setValue] = useState(false);

    return (
        <Container>
            <h1>Test</h1>
            <br />
            <Modal show={value} headerContent={<div>header</div>} footerContent={<div>header</div>}>
                <div>Contetn</div>
            </Modal>

            <MotifButton onClick={() => setValue(!value)} type="button" variant="secondary">
                Show Modal
            </MotifButton>

            <DatePicker value={new Date()} onChange={() => null} label="Publish Date" />
        </Container>
    );
};
export default Test;
