class WindowService {
    public static reloadPage() {
        window.location.reload();
    }

    public static reloadApp() {
        window.location.assign('/');
    }
}

export default WindowService;
