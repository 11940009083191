import { FC } from 'react';
import { MotifErrorMessage } from '../../../assets/lib/motif';

type ErrorMessageProps = {
    children: React.ReactNode;
    className?: string;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ children, className = '' }) => (
    <MotifErrorMessage className={className}>{children}</MotifErrorMessage>
);

export default ErrorMessage;
