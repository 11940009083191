import { FC, ReactNode } from 'react';
import { MotifDropdown } from '../../../assets/lib/motif';

type DropdownProps = {
    trigger?: ReactNode;
    id?: string;
    ariaLabelledBy?: string;
    open?: boolean;
    className?: string;
    children: ReactNode;
    placement?: 'bottom-right' | 'bottom-left';
    handleClickOutside?: () => void;
};

const Dropdown: FC<DropdownProps> = ({
    trigger,
    id = '',
    ariaLabelledBy = '',
    open = false,
    className = '',
    children,
    placement = 'bottom-right',
    handleClickOutside,
    ...other
}) => (
    <MotifDropdown
        trigger={trigger}
        id={id}
        aria-labelledby={ariaLabelledBy}
        open={open}
        className={className}
        placement={placement}
        handleClickOutside={handleClickOutside}
        {...other}>
        {children}
    </MotifDropdown>
);

export default Dropdown;
