import { ReactElement } from 'react';
import { MotifVerticalNavigation } from '../../../assets/lib/motif';

const onChangePlaceholder = () => [];

type SidebarProps<TId = string> = {
    collapse?: boolean;
    selected?: string | null;
    ariaLabel?: string;
    className?: string;
    children: ReactElement | ReactElement[];
    handleSelect?: (id: TId) => void;
};

const Sidebar = <TId,>({
    children,
    handleSelect = onChangePlaceholder,
    ...props
}: SidebarProps<TId>) => {
    return (
        <MotifVerticalNavigation {...props} handleSelect={handleSelect}>
            {children}
        </MotifVerticalNavigation>
    );
};

export default Sidebar;
