import { MotifTextArea } from '../../../assets/lib/motif';

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    testId?: string;
    id: string;
    value: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
};
const TextArea: React.FC<TextAreaProps> = ({
    testId = '',
    id,
    required,
    disabled,
    className,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    ...other
}: TextAreaProps) => {
    return (
        <MotifTextArea
            {...other}
            data-test-id={testId}
            className={className}
            id={id}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};

export default TextArea;
