import styled from 'styled-components';

export const StyledAuthorizedLayout = {
    AppContainer: styled.div``,
    Main: styled.div`
        display: flex;
        margin: 0rem 0 0 0;
        padding: 0;
        min-height: 100vh;
    `,
    ContentWrapper: styled.div`
        flex-grow: 1;
        width: 75%;
        min-height: 100vh;
    `,
    Content: styled.div`
        padding: ${(props) => props.theme.pagePadding};
        min-height: 100vh;

        .header-menu-section {
            display: flex;
            .results-inputs {
                display: flex;
                flex-direction: row;

                .input-icons-section,
                .result-icons-section {
                    display: flex;
                }

                .motif-label {
                    width: 60px;
                    font-size: 0.75rem;
                }

                .motif-icon svg {
                    height: 0.75rem;
                    width: 0.75rem;
                }
            }
        }
    `,
    Page: styled.div``
};
