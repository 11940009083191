import { createSlice } from '@reduxjs/toolkit';
import { IWhatIfScenariosState, IProject } from 'core/base/type/whatIfScenario';
import { createScenarioObject } from './filter.reducer';
import { IFilterGeneric } from 'core/base/type';
import { IWhatIfSelectedProject } from 'Pages/InvestorModule/WhatIf/SensitivityProjectModal/ISensitivityProjectModal';
import { IData } from 'core/base/type/filter';
import { SIMULATION_TYPE } from 'core/base/enum/whatifScenario';

export const selectedProjectInitialState: IWhatIfSelectedProject = {
    id: '',
    name: '',
    currentCommissioningDate: '',
    currentRetirementDate: '',
    disabled: false,
    newCommissioningDate: '',
    newRetirementDate: '',
    isCommissioningAllowed: false,
    isRetirementAllowed: false
};

export const whatIfScenariosInitialState: IWhatIfScenariosState = {
    sensitivityList: {
        selected: '',
        data: []
    },
    scenario: {
        selected: '',
        data: []
    },
    technology: {
        selected: '',
        data: []
    },
    region: {
        selected: '',
        data: []
    },
    simulation: {
        selected: '',
        data: []
    },
    projects: {
        selected: selectedProjectInitialState,
        currentSelection: null,
        data: []
    },
    augmentation: {
        selectedAugmentation: []
    },
    overviewText: '',
    generateSimulation: {
        simulationId: '',
        referenceCase: '',
        publishedDate: '',
        simulationTitle: '',
        createdBy: '',
        simulationDescription: '',
        simulationsList: [],
        status: ''
    },
    addCustomProjects: {
        selected: {
            id: '',
            name: ''
        },
        traces: {
            selected: {
                id: 0,
                traceId: '',
                location: '',
                distance: 0,
                capacityFactor: 0
            },
            data: []
        },
        buses: {
            selected: {
                id: 0,
                busNumber: 0,
                bus: '',
                voltage: 0,
                distance: 0
            },
            data: []
        },
        projectDetails: {
            project: '',
            proposedCommissioningDate: '',
            latitude: '',
            longitude: ''
        },
        projects: []
    }
};

const createFiltersObject = (
    actionObj: Array<IFilterGeneric>
): { updatedData: Array<IFilterGeneric>; selectedData: string } => {
    const updatedData: Array<IFilterGeneric> = [];
    let selectedData = '';
    if (actionObj) {
        actionObj?.map((values) => {
            updatedData?.push({
                name: values?.name,
                id: String(values?.id)
            });
        });
        selectedData = String(actionObj[0].id);
    }
    return { updatedData, selectedData };
};

const createTechFilter = (payload: any[], selectedRegionData: string) => {
    const updatedData: Array<IFilterGeneric> = [];
    let selectedData = '';
    if (payload) {
        payload?.map((values) => {
            if (selectedRegionData === String(values?.id)) {
                values?.technology?.map((tech: { name: string; id: string }) => {
                    updatedData?.push({
                        name: tech?.name,
                        id: String(tech?.id)
                    });
                });
                selectedData = String(values?.technology[0]?.id);
            }
        });
    }
    return { updatedData, selectedData };
};

const whatIfScenariosReducer = createSlice({
    name: 'whatIfScenarios',
    initialState: whatIfScenariosInitialState,
    reducers: {
        setSensitivityList: (state, action) => {
            state.sensitivityList.data = action?.payload;
        },
        setScenario: (state, action) => {
            if (action?.payload?.scenarioData && action?.payload?.scenarioData[0]?.id) {
                const scenarioId = String(action?.payload?.scenarioData[0]?.id);
                state.scenario.data = createScenarioObject(action?.payload?.scenarioData);
                if (state.scenario.selected === '') {
                    state.scenario.selected = scenarioId;
                }
            }
        },
        setSimulation: (state, action) => {
            state.simulation.data = action?.payload;
        },
        setRegions: (state, action) => {
            const finalData = createFiltersObject(action?.payload?.regionTechnology);
            state.region.data = finalData?.updatedData;
            // hold region selected on card change
            if (state.region.selected === '') {
                state.region.selected = finalData?.selectedData;
            }
        },
        setTechnology: (state, action) => {
            const { data, selectedRegion } = action?.payload;
            if (selectedRegion) {
                const finalTechData = createTechFilter(data?.regionTechnology, selectedRegion);
                state.technology.data = finalTechData?.updatedData;
                state.technology.selected = finalTechData?.selectedData;
            }
        },
        setProjects: (state, action) => {
            state.projects.data = action?.payload;
        },
        setSelectedSensitivity: (state, action) => {
            state.sensitivityList.selected = action?.payload;
        },
        setSelectedScenarioSelectedId: (state, action) => {
            state.scenario.selected = action.payload;
        },
        setSelectedScenario: (state, action) => {
            state.scenario.selected = action?.payload;
            // reset to region and technology default selection
            state.region.selected = state.region?.data[0]?.id;
            state.technology.selected = state.technology?.data[0]?.id;
        },
        setSelectedSimulation: (state, action) => {
            state.simulation.selected = action?.payload;
        },
        setSelectedRegions: (state, action) => {
            state.region.selected = action?.payload;
        },
        setSelectedTechnology: (state, action) => {
            state.technology.selected = action?.payload;
        },
        setSelectedProjects: (state, action) => {
            state.projects.selected = action?.payload;
            if (action?.payload) {
                state.projects.currentSelection = action?.payload;
            }
        },
        setSimulationReferenceCase: (state, action) => {
            state.generateSimulation.referenceCase = action.payload;
        },
        updateGenerateSimulation: (state, action) => {
            state.generateSimulation.publishedDate = action?.payload.publishedDate;
            state.generateSimulation.referenceCase = action?.payload.referenceCase;
            state.generateSimulation.simulationTitle = action?.payload.simulationTitle;
            state.generateSimulation.simulationDescription = action?.payload.simulationDescription;
            state.generateSimulation.simulationId = action?.payload.simulationId;
            state.generateSimulation.createdBy = action?.payload.createdBy;
            state.generateSimulation.status = action?.payload.status;
        },
        updateGenerateSimulationList: (state, action: { type: string; payload: any }) => {
            if (action.payload.type === SIMULATION_TYPE.SENSITIVITY) {
                state.generateSimulation.simulationsList[0] = action?.payload.payload;
            } else if (action.payload.type === SIMULATION_TYPE.AUGMENTATION_SETS) {
                state.generateSimulation.simulationsList[1] = action?.payload.payload;
            } else if (action.payload.type === SIMULATION_TYPE.CUSTOM_PROJECT) {
                state.generateSimulation.simulationsList[2] = action?.payload.payload;
            }
        },
        resetGenerateSimulation: (state) => {
            state.generateSimulation = whatIfScenariosInitialState.generateSimulation;
        },
        setSelectedStimulationList: (state, action) => {
            state.generateSimulation.simulationsList.push(action?.payload?.filterPayload);
            if (action?.payload?.filterType === 'projects') {
                const selectedProject = state.projects.data.map((item) =>
                    item?.id === action?.payload?.filterPayload?.id
                        ? { ...item, disabled: true }
                        : item
                );

                state.projects.data = selectedProject;
            }
        },
        resetSensitivityList: (state) => {
            state.generateSimulation.simulationsList[0] = [];
        },
        resetCustomProjectList: (state) => {
            state.generateSimulation.simulationsList[2] = [];
        },
        finishSelectedSimulations: (state) => {
            state.generateSimulation.simulationsList = [];
            state.generateSimulation.simulationId =
                whatIfScenariosInitialState.generateSimulation.simulationId;
            state.generateSimulation.publishedDate =
                whatIfScenariosInitialState.generateSimulation.publishedDate;
            state.generateSimulation.simulationTitle =
                whatIfScenariosInitialState.generateSimulation.simulationTitle;
            state.generateSimulation.simulationDescription =
                whatIfScenariosInitialState.generateSimulation.simulationDescription;
            state.generateSimulation.createdBy =
                whatIfScenariosInitialState.generateSimulation.createdBy;
            state.generateSimulation.status = whatIfScenariosInitialState.generateSimulation.status;
        },
        addCustomProjectTechnology: (state, action: { type: string; payload: IData }) => {
            state.addCustomProjects.selected = action.payload;
        },
        resetCustomProjectTechnology: (state) => {
            state.addCustomProjects.selected =
                whatIfScenariosInitialState.addCustomProjects.selected;
        },
        addCustomProjects: (state, action: { type: string; payload: IProject }) => {
            state.addCustomProjects.projects.push(action.payload);
        },
        setTraces: (state, action) => {
            state.addCustomProjects.traces.data = action.payload;
        },
        setBuses: (state, action) => {
            state.addCustomProjects.buses.data = action.payload;
        },
        setProjectDetails: (state, action) => {
            state.addCustomProjects.projectDetails = action.payload;
        }
    }
});

export const {
    setSensitivityList,
    setScenario,
    setSimulation,
    setRegions,
    setTechnology,
    setProjects,
    setSelectedScenario,
    setSelectedSensitivity,
    setSelectedSimulation,
    setSelectedRegions,
    setSelectedTechnology,
    setSelectedProjects,
    setSimulationReferenceCase,
    setSelectedStimulationList,
    resetSensitivityList,
    resetCustomProjectList,
    finishSelectedSimulations,
    resetGenerateSimulation,
    updateGenerateSimulation,
    setSelectedScenarioSelectedId,
    updateGenerateSimulationList,
    addCustomProjectTechnology,
    resetCustomProjectTechnology,
    addCustomProjects,
    setTraces,
    setBuses,
    setProjectDetails
} = whatIfScenariosReducer.actions;
export default whatIfScenariosReducer.reducer;
