import { FC } from 'react';
import { MotifFormField } from '../../../assets/lib/motif';

type FormFieldProps = {
    children: React.ReactNode;
    className?: string;
};
const FormField: FC<FormFieldProps> = ({ children, className = '' }) => (
    <MotifFormField className={className}>{children}</MotifFormField>
);

export default FormField;
