import { FC } from 'react';
import { MotifTabControl } from '../../../../assets/lib/motif';

type TabControlProps = {
    children: React.ReactNode;
    testId?: string;
    className?: string;
    disabled?: boolean;
    onTabClick?: () => void;
};

const TabControl: FC<TabControlProps> = ({
    children,
    testId = '',
    className = '',
    onTabClick,
    ...other
}: TabControlProps) => {
    return (
        <MotifTabControl
            {...other}
            data-test-id={testId}
            className={`tab-control ${className}`}
            onClick={() => {
                onTabClick && onTabClick();
            }}>
            {children}
        </MotifTabControl>
    );
};

export default TabControl;
