import React from 'react';
import styled from 'styled-components';
import { MotifProgressIndicator } from '@ey-xd/motif-react';

type ProgressIndicatorProps = {
    children: React.ReactNode;
    className?: string;
    variant?: string;
};

const ProgressIndicator = styled(
    ({ children, className = '', variant = 'row', ...props }: ProgressIndicatorProps) => (
        <MotifProgressIndicator className={className} variant={variant} {...props}>
            {children}
        </MotifProgressIndicator>
    )
)`
    &.custom-progress-indicator {
        --progress-indicator-icon-active-icon-color: #2e2e38;
        --progress-indicator-body--bg-color: transparent;
        --progress-indicator-body-hover--bg-color: transparent;
        --progress-indicator-body-active--bg-color: transparent;
        --progress-indicator-title--color: #c4c4cd;
        --progress-indicator-title-active--color: #f6f6fa;

        margin-bottom: 40px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 18px;
            border-top: 1px solid #747480;
            width: 100%;
            height: 0;
        }

        .motif-progress-indicator-step {
            margin: 0;
            padding-top: 0;
            flex-grow: 0;
            width: auto;
            align-items: center;

            &:before {
                margin: 18px 0 0;
                height: 1px;
                background: ${({ theme }) => theme.bodyBg};
                width: 52px;
                left: 50%;
                transform: translateX(-50%);
            }

            &:first-child:before,
            &:last-child:before {
                width: 50%;
                transform: translateX(0);
            }

            &:first-child:before {
                left: 0;
            }
            &:last-child:before {
                left: auto;
                right: 0;
            }
        }
        .motif-progress-indicator-icon {
            width: 36px;
            height: 36px;
            background: ${({ theme }) => theme.gray};
            border-radius: 50%;
            position: relative;
            outline: 8px solid ${({ theme }) => theme.bodyBg};

            .icon {
                height: 24px;
            }
            .icon svg {
                height: 24px;
                width: 24px;
                fill: #2e2e38;
                border: none;
            }
        }
        .motif-progress-indicator-step-active .motif-progress-indicator-icon {
            background: var(--brand-color);
        }
        .motif-progress-indicator-icon-svg {
            display: none;
        }
        .motif-progress-indicator-heading {
            margin: 0;
            padding: 16px 10px;
        }
    }
`;
export default ProgressIndicator;
