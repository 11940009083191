import React, { FC } from 'react';
import ModelHeader from './ModalHeader';
import StyledModel from './Modal.styled';
import ModelFooter from './ModalFooter';
import ModelBody from './ModalBody';
import ErrorMessage from '../ErrorMessage';

export enum ModalSize {
    DEFAULT = 'default',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
    EXTRA_LARGE = 'xl',
    XXL = 'xxl',
    XXXL = 'xxxl'
}

export enum ModalVariant {
    DEFAULT = 'default',
    ALT = 'alt'
}

export enum ModalPlacement {
    DEFAULT = 'default',
    SLIDE_OUT = 'slideout'
}

type FocusTrapOption = {
    allowOutsideClick?: boolean;
    initialFocus?: boolean;
};

export interface IModalProps {
    show?: boolean;
    variant?: 'default' | 'alt' | 'slideout'; //workaround: added slideout due to active bug -> https://motif-feedback.ideas.aha.io/ideas/MOT-I-63
    placement?: 'default' | 'slideout';
    size?: ModalSize;
    takeover?: boolean;
    className?: string;
    closeModalButton?: React.ReactNode;
    closeButtonProps?: Record<string, unknown>;
    modalExtraProps?: React.ReactHTMLElement<HTMLDivElement>;
    headerClassName?: string;
    headerContent?: React.ReactNode;
    headerExtraProps?: React.ReactHTMLElement<HTMLDivElement>;
    bodyClassName?: string;
    bodyExtraProps?: Record<string, unknown>;
    footerClassName?: string;
    footerContent?: React.ReactNode;
    footerExtraProps?: React.ReactHTMLElement<HTMLDivElement>;
    children?: React.ReactNode;
    showFooter?: boolean;
    focusTrapOptions?: FocusTrapOption;
    onClose?: () => void;
    errorMessage?: string;
}

const Modal: FC<IModalProps> = ({
    show = false,
    className = '',
    placement = 'default',
    size = ModalSize.DEFAULT,
    takeover = false,
    variant = 'default',
    children,
    closeModalButton,
    closeButtonProps = {},
    modalExtraProps = {},
    headerClassName = '',
    headerContent = '',
    headerExtraProps = {},
    bodyClassName = '',
    bodyExtraProps = {},
    footerClassName = '',
    footerContent,
    footerExtraProps = {},
    showFooter = true,
    focusTrapOptions = {},
    onClose,
    errorMessage
}) => {
    let customClassName = `emass-modal ${className}`;
    if (size === ModalSize.XXL) {
        customClassName = `emass-modal ${className} motif-modal-size-xxl`;
    } else if (size === ModalSize.XXXL) {
        customClassName = `emass-modal ${className} motif-modal-size-xxxl`;
    }

    return (
        <StyledModel
            {...modalExtraProps}
            aria-modal={true}
            aria-hidden={!show}
            aria-label={`${headerContent}`}
            show={show}
            onClose={onClose}
            placement={placement}
            variant={variant}
            size={size}
            takeover={takeover}
            focusTrapOptions={focusTrapOptions}
            className={customClassName}>
            <ModelHeader
                {...headerExtraProps}
                closeModalButton={closeModalButton}
                closeButtonProps={closeButtonProps}
                headerClassName={headerClassName}>
                {headerContent}
            </ModelHeader>
            <ModelBody {...bodyExtraProps} bodyClassName={bodyClassName}>
                {children}
            </ModelBody>
            {errorMessage && (
                <ErrorMessage className="custom-error-message">{errorMessage}</ErrorMessage>
            )}
            {showFooter && (
                <ModelFooter {...footerExtraProps} footerClassName={footerClassName}>
                    {footerContent}
                </ModelFooter>
            )}
        </StyledModel>
    );
};

export default Modal;
