import { RouteManagerContext } from 'common/context';
import { RouteManagerContextType } from 'common/context/routemanager.context';
import { removeHashFromURL, sanitizeQueryString } from 'common/utils';
import { IRouteType } from 'components/custom/TabNav/ITabNav';
import { SUB_PAGE } from 'core/base/const';
import { FC, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

type RouteLayoutProps = {
    children: React.ReactNode;
};

const RouteManager: FC<RouteLayoutProps> = ({ children }) => {
    const navigate = useNavigate();

    const redirectTo = useCallback(
        (
            paramsForReplace: {
                [key: string]: string | boolean | number | undefined;
            },
            queryString = removeHashFromURL(),
            routeType: IRouteType = SUB_PAGE
        ): void => {
            const isSubTab = routeType === SUB_PAGE;

            const mainRouteObj = {
                module: paramsForReplace?.module as string,
                main: paramsForReplace?.main as string,
                subpage: paramsForReplace?.subpage as string
            };

            let path = '';
            if (window.location.pathname !== '/') {
                const nextPath = isSubTab
                    ? generatePath('/:module/:main/:subpage', mainRouteObj)
                    : generatePath('/:module/:main/:subpage/:childSubpage', {
                          ...mainRouteObj,
                          childSubpage: String(paramsForReplace?.childSubpage)
                      }); // Altered for the typescript error

                path = `${nextPath}${sanitizeQueryString(queryString)}`;
                if (!path.startsWith('/')) {
                    path = `/${path}`;
                }
                path.replaceAll('&', '%26');
            }

            navigate(path);
        },
        [navigate]
    );

    const contextValue: RouteManagerContextType = {
        redirectTo
    };

    return (
        <RouteManagerContext.Provider value={contextValue}>{children}</RouteManagerContext.Provider>
    );
};

export default RouteManager;
