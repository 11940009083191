import { FC, HTMLAttributes } from 'react';
import { MotifLabel } from '../../../assets/lib/motif';

type LabelProps = HTMLAttributes<HTMLLabelElement> & {
    children: string;
    className?: string;
};
const Label: FC<LabelProps> = ({ children = '', className = '', ...other }: LabelProps) => {
    return (
        <MotifLabel className={className} {...other}>
            {children}
        </MotifLabel>
    );
};

export default Label;
