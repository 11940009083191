import { FC, ReactNode } from 'react';
import { MotifVerticalNavigationMenu } from '../../../../assets/lib/motif';

type SidebarMenuProps = {
    className?: string;
    children: ReactNode;
};

const SidebarMenu: FC<SidebarMenuProps> = ({ children, className = '' }: SidebarMenuProps) => {
    return (
        <MotifVerticalNavigationMenu className={className}>{children}</MotifVerticalNavigationMenu>
    );
};

export default SidebarMenu;
