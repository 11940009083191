import React, { FC, useEffect, useState } from 'react';
import { StyleAppFooter } from '../AppFooter.style';
import Loader from '../../../base/Loader';
import PolicyContent from '../PolicyContent/PolicyContent';
import ErrorMessage from '../../../base/ErrorMessage';
import { MODAL_BUTTONS, GLOBAL_ERROR_BOUNDARY_MESSAGE, FOOTER } from 'core/base/const';
import Modal, { ModalSize } from '../../../base/Modal';
import Button from '../../../base/Button';
import { getPrivacyPolicyContent } from 'core/http';
import { POLICY_TYPE } from 'core/base/enum';
import { IPolicyTypeObject } from 'core/store/userProfile.reducer';

interface IFooterModalProps {
    show: boolean;
    type: string;
    onClose: () => void;
}
interface IModalBodyProps {
    loading: boolean;
    data: string;
    error: boolean;
}
type IPolicyTypeListObject = {
    policyTypeList?: IPolicyTypeObject[];
};

const ModalBody: FC<IModalBodyProps> = ({ loading, data, error }) => {
    if (loading) {
        return <Loader />;
    } else if (error) {
        return (
            <div className="loadIndicator">
                <ErrorMessage>
                    {`${GLOBAL_ERROR_BOUNDARY_MESSAGE.title} ${GLOBAL_ERROR_BOUNDARY_MESSAGE.description}`}
                </ErrorMessage>
            </div>
        );
    }
    return <PolicyContent content={data} />;
};

const FooterModal: React.FC<IFooterModalProps> = ({ show, type, onClose }) => {
    const [data, setData] = useState('');
    const [apiError, setApiError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const updateData = async (type: POLICY_TYPE) => {
        const responseData: IPolicyTypeListObject = await getPrivacyPolicyContent(type);
        if (responseData?.policyTypeList && responseData?.policyTypeList.length > 0) {
            const filterData = responseData?.policyTypeList.filter(
                (policy) => policy.policyType === type
            )[0];
            setData(filterData?.content);
            setApiError(false);
        } else {
            setApiError(true);
        }
    };

    const loadData = async () => {
        if (type === FOOTER.privacyNotice) {
            await updateData(POLICY_TYPE.PRIVACY_NOTICE);
        } else if (type === FOOTER.termsOfUse) {
            await updateData(POLICY_TYPE.TERMS_OF_USE);
        } else {
            setData('');
        }
        setLoading(false);
    };

    useEffect(() => {
        if (show) {
            setLoading(true);
            loadData();
        }
    }, [show]);

    return (
        <StyleAppFooter>
            <Modal
                show={show}
                size={ModalSize.EXTRA_LARGE}
                headerContent={type}
                onClose={onClose}
                footerContent={
                    <Button type="button" onClick={onClose}>
                        {MODAL_BUTTONS.close}
                    </Button>
                }>
                <ModalBody loading={loading} data={data} error={apiError} />
            </Modal>
        </StyleAppFooter>
    );
};

export default React.memo(FooterModal);
