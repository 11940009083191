import { FC, ReactElement } from 'react';
import { MotifVerticalNavigationContent } from '../../../../assets/lib/motif';

type SidebarContentProps = {
    className?: string;
    children: ReactElement;
};

const SidebarContent: FC<SidebarContentProps> = ({
    children,
    className = ''
}: SidebarContentProps) => {
    return (
        <MotifVerticalNavigationContent className={className}>
            {children}
        </MotifVerticalNavigationContent>
    );
};

export default SidebarContent;
