import React, { useState } from 'react';
import {
    MotifInlineMessage,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader
} from 'assets/lib/motif';
import { Button, Checkbox } from 'components/base';
import { BUTTONS } from './Policies.label.const';
import { GLOBAL_ERROR_BOUNDARY_MESSAGE } from 'core/base/const';
import { POLICY_TYPE } from 'core/base/enum';

type PolicyModalProps = {
    type: POLICY_TYPE;
    title: string;
    children: React.ReactNode;
    agreeText: string;
    handleActionButton: (type: POLICY_TYPE) => void;
    isError: boolean;
};

const PolicyModal: React.FC<PolicyModalProps> = ({
    type,
    title,
    children,
    agreeText,
    handleActionButton,
    isError
}) => {
    const [checkboxAgree, setCheckboxAgree] = useState(false);
    const checkboxName = `checkbox-${title.replace(/\s/g, '')}`;
    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxAgree(event.target.checked);
    };
    return (
        <>
            <MotifModalHeader>{title}</MotifModalHeader>
            <MotifModalBody>{children}</MotifModalBody>
            <MotifModalFooter>
                <div className="footer-wrapper">
                    <Checkbox
                        id={checkboxName}
                        value={checkboxName}
                        name={checkboxName}
                        checked={checkboxAgree}
                        onChange={handleCheckbox}>
                        <p className="agree-to-privacy">{agreeText}</p>
                    </Checkbox>
                    <Button
                        onClick={() => handleActionButton(type)}
                        type="button"
                        disabled={!checkboxAgree}>
                        {BUTTONS.CONTINUE}
                    </Button>
                    {isError && (
                        <div className="policy-error">
                            <MotifInlineMessage variant="error">
                                {`${GLOBAL_ERROR_BOUNDARY_MESSAGE.title} ${GLOBAL_ERROR_BOUNDARY_MESSAGE.description}`}
                            </MotifInlineMessage>
                        </div>
                    )}
                </div>
            </MotifModalFooter>
        </>
    );
};

export default PolicyModal;
