import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IUserProfileAction {
    displayName: string;
    email: string;
    isExternal: boolean;
    profileImage: string;
}

export type IPolicyTypeObject = {
    content: string;
    hasAccepted: boolean;
    policyType: string | number;
    version: string | number;
};

export type IUserProfileState = {
    loading: boolean;
    logout: boolean;
    userData: IUserProfileAction;
    logoutError: boolean;
    showPolicyModal: boolean;
    policyTypeList: IPolicyTypeObject[];
};

export const userProfileInitialState: IUserProfileState = {
    loading: true,
    logout: false,
    userData: {
        displayName: '',
        email: '',
        isExternal: false,
        profileImage: ''
    },
    logoutError: false,
    showPolicyModal: false,
    policyTypeList: []
};

const userProfileReducer = createSlice({
    name: 'userProfile',
    initialState: userProfileInitialState,
    reducers: {
        setUserProfile: (state, action: PayloadAction<IUserProfileAction>) => {
            const { displayName, email, isExternal, profileImage } = action.payload;
            state.userData.displayName = displayName;
            state.userData.email = email;
            state.userData.isExternal = isExternal;
            state.userData.profileImage = profileImage;
            state.loading = false;
        },
        setUserLogout: (state, action: PayloadAction<boolean>) => {
            state.loading = false;
            state.logout = action.payload;
        },
        setUserLogoutError: (state, action: PayloadAction<boolean>) => {
            state.loading = false;
            state.logoutError = action.payload;
        },
        showPolicyModal: (state, action) => {
            (state.showPolicyModal = action.payload.showPolicy),
                (state.policyTypeList = action.payload.policyTypeList);
        }
    }
});

export const { setUserProfile, setUserLogout, setUserLogoutError, showPolicyModal } =
    userProfileReducer.actions;
export default userProfileReducer.reducer;
