export const ApiCacheTags = {
    SCENARIO_LIBRARY: 'scenario-libarary',
    SENSITIVITY: 'sensitivity',
    AGUMENTATAION_TIMINGS: 'AGUMENTATAION_TIMINGS',
    CUSTOM_PROJECTS: 'CUSTOM_PROJECTS',
    VALIDATION: 'VALIDATION',
    INCHART_YEAR_FILTER: 'INCHART_YEAR_FILTER'
};

export const RTK_QUERY_CACHE_TIME = 86400;
export const API_ENDPOINTS = {
    FILTER: '/configuration/scenarios',
    REGIONS: '/configuration/regions',
    REGIONS_TECHNOLOGY: '/configuration/region-technology',
    SCENARIO_REGION_TECHNOLOGY: '/configuration/scenarios/region-technology',
    TECHNOLOGIES: '/configuration/technology-colorcode-order',
    INTERCONNECTOR: '/configuration/interconnectors',
    SENSITIVITY_SCENARIOS: '/configuration/sensitivity-scenarios',
    PROJECT: '/configuration/projects',
    SCENARIO_PROJECT: '/configuration/scenarios/projects',
    GENERATOR_BY_TECHNOLOGY: {
        INSTALLED_CAPCITY: 'scenario-outcomes/generators-by-technology/installed-capacity',
        YEAR_ON_YEAR_CAPACITY_CHANGE:
            'scenario-outcomes/generators-by-technology/year-on-year-capacity-change',
        GENERATION_MIX_AS_GENERATED:
            'scenario-outcomes/generators-by-technology/generationmix-as-gen'
    },
    PRICES: {
        AVERAGE_ANNUAL_PRICE: 'scenario-outcomes/prices/average-annual-price',
        AVERAGE_TIME_OF_DAY_PRICE: 'scenario-outcomes/prices/average-time-day-price',
        TIME_OF_DAY_PER_YEAR: 'scenario-outcomes/prices/time-of-day-per-year',
        TIME_OF_DAY_PER_YEAR_FILTER: 'scenario-outcomes/prices/time-of-day-per-year/years'
    },
    INTERCONNECTOR_FLOWS: {
        AVERAGE_ANNUAL_INTERCONNECTOR_FLOW: 'scenario-outcomes/interconnector-flows/average-annual',
        AVERAGE_TIME_OF_DAY: 'scenario-outcomes/interconnector-flows/average-time-day',
        AVERAGE_TIME_OF_DAY_YEAR_FILTER: 'scenario-outcomes/interconnector-flows/time-of-day/years'
    },
    PROJECT_PERFORMANCE: {
        AVERAGE_TIME_OF_DAY_GENERATION:
            'project-performance/generation-outcomes/average-time-day-generation',
        AVERAGE_TIME_OF_DAY_GENERATION_YEAR_FILTER:
            'scenario-outcomes/prices/time-of-day-per-year/years',
        REVENUE: 'project-performance/revenue',
        GENERATION_PER_FINANCIAL_YEAR:
            'project-performance/generation-outcomes/generation-financial-year',
        TIME_WEIGHTED_AND_DISPATCH_WEIGHTED_PRICE:
            'project-performance/price-outcomes/time-and-dispatch-weighted-price',
        MLF_VS_GENERATION_DISPATCHED:
            'project-performance/marginal-loss-factor/mlf-vs-generation-dispatched',
        MLF_VS_GENERATION_DISPATCHED_YEAR:
            'project-performance/marginal-loss-factor/mlf-progression/years',
        MARGINAL_LOSS_FACTOR: 'project-performance/marginal-loss-factor/mlf-progression'
    },
    TARGET_FULFILMENT: {
        UNSERVED_ENERGY: 'scenario-outcomes/target-fulfillment/unserved-energy',
        TOTAL_EMISSIONS: 'scenario-outcomes/target-fulfillment/total-emissions'
    },
    MARKET_OUTCOMES: {
        INSTALLED_CAPACITY_DIFFERENCE:
            'scenario-outcomes/generators-by-technology/installed-capacity-difference',
        GENERATION_MIX_DIFFERENCE:
            'scenario-outcomes/generators-by-technology/generationmix-as-gen-difference',
        INTERCONNECTOR_FLOW:
            'scenario-outcomes/interconnector-flows/comparison-capabilities/average-annual',
        AVERAGE_ANNUAL_PRICE:
            'scenario-outcomes/prices/comparison-capabilities/average-annual-price',
        TOD_AVARAGE_PRICE: 'scenario-outcomes/prices/comparison-capabilities/tod-average-price',
        TOD_AVERAGE_PRICE_YEAR:
            'scenario-outcomes/prices/comparison-capabilities/tod-average-price/years'
    },
    PROJECT_OUTCOMES: {
        AVERAGE_GENERATION_BY_TOD:
            'project-performance/generation-outcomes/comparison-capabilities/average-time-day-generation',
        AVERAGE_GENERATION_BY_TOD_YEAR:
            '/project-performance/generation-outcomes/average-time-day-generation/years',
        DWP_COMPARISON:
            '/project-performance/price-outcomes/comparison-capabilities/dispatch-weighted-price',
        ANNUAL_REVENUE: '/project-performance/comparison-capabilities/revenue',
        ANNUAL_ACHIEVED_GENERATION:
            '/project-performance/generation-outcomes/comparison-capabilities/annual-achieved-generation',
        MLF_OUTCOMES_COMPARISON:
            '/project-performance/marginal-loss-factor/comparison-capabilities/mlf-outcomes'
    },
    LOGOUT: 'user/sign-out',
    WHAT_IF_SCENARIOS: {
        SENSITIVITYLIST: 'sensitivity-list',
        SCENARIOS: '/configuration/scenarios',
        REGION: '/configuration/region-technology',
        SENSITIVITY_ONE_TECHNOLOGIES: 'scenario-library/sensitivity-technology-filter',
        CUSTOM_TECHNOLOGY: 'custom-technology-list',
        SIMULATION: 'simulation-list',
        PROJECTS: '/configuration/projects',
        PROJECT_DETAILS: 'modify-generator-schedule/project-details',
        SAVE_SCENARIO: 'scenario-library/create',
        SAVE_SENSITIVITY: 'modify-generator-schedule/save',
        EDIT_SENSITIVITY: 'modify-generator-schedule/save',
        EDIT_SCENARIO: 'scenario-library/update',
        GET_SENSITIVITY: 'modify-generator-schedule/sensitivity-details',
        GET_AGUMENTATION_DETAILS: 'modify-augmentation-timings/emaas-augmentation',
        DELETE_SENSITIVITY: 'modify-generator-schedule/sensitivity-details',
        DELETE_AUGMENTATION: 'modify-augmentation-timings/delete',
        SAVE_AUGMENTATION: 'modify-augmentation-timings/save',
        EDIT_AUGMENTATION: 'modify-augmentation-timings/save',
        GET_AUGMENTATION_TIMINGS: 'modify-augmentation-timings/augmentation-timings',
        CUSTOM_PROJECT_TECHNOLOGIES: 'add-custom-project/technology-list',
        GET_TRACES: 'add-custom-project/traces',
        GET_BUSES: 'add-custom-project/buses',
        SAVE_CUSTOM_PROJECT: 'add-custom-project/save',
        GET_CUSTOM_PROJECTS: 'add-custom-project/custom-projects',
        DELETE_CUSTOM_PROJECT: 'add-custom-project/delete-custom-project',
        EDIT_CUSTOM_PROJECT: 'add-custom-project/update',
        DOWNLOAD_ASSUMPTION: 'scenario-library/sas-download-assumption'
    },
    SCENARIO_LIBRARY: {
        SCENARIOS_LIST: 'scenario-library/scenario-details',
        DELETE_LIST: 'scenario-library/delete',
        RUN_SCENARIO: 'scenario-library/run-scenario',
        VALIDATION: 'scenario-library/validate-sensitivity-impacts'
    },
    NETWORK: {
        MODELLED_NETWORK: 'scenario-outcomes/network/modelled-network',
        CONSTRAINT_BINDING: 'scenario-outcomes/network/constraint-binding-heatmap',
        CONGESTION_OUTCOMES: 'scenario-outcomes/network/network-congestion',
        YEARS: 'scenario-outcomes/network/modelled-network/years'
    },

    YEARS_DROPDOWN: 'scenario-outcomes/interconnector-flows/time-of-day/years',
    ACCEPT_POLICY: 'paans/acceptpolicy',
    GET_PRIVACY_POLICY: 'paans/policytype'
};
