import { FC } from 'react';
import { MotifDropdownItem } from 'assets/lib/motif';

type DropdownItemProps = {
    className?: string;
    children: React.ReactNode;
    onClick?: () => void;
};

const DropdownItem: FC<DropdownItemProps> = ({ className = '', children, ...other }) => {
    return (
        <MotifDropdownItem className={className} {...other}>
            {children}
        </MotifDropdownItem>
    );
};

export default DropdownItem;
