import { FC, useEffect } from 'react';
import { MotifToast } from '../../../assets/lib/motif';

type ToastProps = {
    actionName?: string;
    variant?: 'info' | 'warning' | 'error' | 'success';
    position?: 'top' | 'bottom' | '';
    duration?: number;
    className?: string;
    message: string;
    onActionClick?: () => void;
    onClose?: (isAutoClose: boolean) => void;
    icons?: { close?: React.ReactNode; toast?: React.ReactNode };
    isAutoClose?: boolean;
};

const defaultDuration = 3000;

const Toast: FC<ToastProps> = ({
    actionName,
    variant = 'success',
    position = 'top',
    className = '',
    duration = defaultDuration,
    message,
    onActionClick,
    onClose,
    icons,
    isAutoClose,
    ...other
}: ToastProps) => {
    useEffect(() => {
        if (isAutoClose) {
            const timer = setTimeout(() => {
                onClose?.(true);
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [isAutoClose]);
    return (
        <MotifToast
            variant={variant}
            actionName={actionName}
            className={`toast ${className}`}
            position={position}
            actionOnClick={onActionClick}
            onClose={() => {
                onClose?.(false);
            }}
            icons={icons}
            {...other}>
            {message}
        </MotifToast>
    );
};

export default Toast;
