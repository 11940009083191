import React, { FC, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { isDevEnv } from './common/utils/env';
import { CustomNavigationClient } from 'common/utils/NavigationClient';
import { ErrorBoundary } from './components/custom/ErrorBoundary';
import WindowService from './common/utils/window.utils';
import { AppLayout } from './layouts';
import { Loader } from './components/base';
import { ROUTES } from './core/base/const/routes';
import { INVESTOR_MODULE_ROUTES } from 'core/base/enum';
import Test from './test';
import PageNotFound from './Pages/PageNotFound';
import RouteManager from 'layouts/RouterManager/RouteManager';

const InvestorModuleRoute = React.lazy(() => import('./Pages/InvestorModule'));

interface IApp {
    pca: IPublicClientApplication;
}

const App: FC<IApp> = ({ pca }) => {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    const { pathname } = useLocation();
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        if (hasError) {
            WindowService.reloadPage();
        }
    }, [pathname, hasError]);

    const handleError = () => {
        setHasError(true);
    };

    return (
        <ErrorBoundary onError={handleError}>
            <MsalProvider instance={pca}>
                <RouteManager>
                    <AppLayout>
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route
                                    path={ROUTES.DEFAULT}
                                    element={
                                        <Navigate
                                            to={`/${ROUTES.INVESTOR_MODULE}/${ROUTES.INVESTOR_MODULE_ROUTES.DASHBOARD}/${INVESTOR_MODULE_ROUTES.OVERVIEW}`}
                                        />
                                    }
                                />
                                <Route
                                    path={`/${ROUTES.INVESTOR_MODULE}/${ROUTES.INVESTOR_MODULE_ROUTES.DASHBOARD}/${ROUTES.WILD}`}
                                    element={<InvestorModuleRoute />}
                                />
                                <Route
                                    path={`${ROUTES.TEST}/${ROUTES.WILD}`}
                                    element={
                                        isDevEnv ? <Test /> : <Navigate to={ROUTES.NOT_FOUND} />
                                    }
                                />
                                <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
                                <Route
                                    path={ROUTES.WILD}
                                    element={<Navigate to={ROUTES.NOT_FOUND} />}
                                />
                            </Routes>
                        </Suspense>
                    </AppLayout>
                </RouteManager>
            </MsalProvider>
        </ErrorBoundary>
    );
};

export default App;
