import { FC } from 'react';
import {
    MsalAuthenticationTemplate,
    MsalAuthenticationResult,
    IMsalContext,
    useIsAuthenticated
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { AuthorizedLayout, GuestLayout } from '.';
import AppIntroduction from 'components/custom/AppIntroduction/AppIntroduction';
import Policies from 'Pages/LandingPage/policyLandingPage/Policies';
import { RootState, useAppSelector } from 'core/store';
import { loginRequest } from 'core/configs/authConfig';
import { Loader } from 'components/base';
import { FallBack } from 'components/custom/ErrorBoundary';

type AppLayoutProps = {
    children?: React.ReactNode;
};

interface RenderComponentProps {
    children: React.ReactNode;
    showPolicyModal: boolean;
}

const RenderComponent: FC<RenderComponentProps> = ({ children, showPolicyModal }) => {
    if (showPolicyModal) {
        return <Policies />;
    }
    return <AuthorizedLayout>{children}</AuthorizedLayout>;
};

const ErrorComponent: FC<MsalAuthenticationResult> = ({ error }) => {
    return <FallBack reloadDocument={true} message={error?.errorMessage} />;
};
const LoadingComponent: FC<IMsalContext> = () => {
    return <Loader fullScreen={true} />;
};

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
    const isAuthenticated = useIsAuthenticated();

    const showPolicyModal = useAppSelector(
        (state: RootState) => state?.userProfile?.showPolicyModal
    );

    return (
        <>
            {isAuthenticated ? (
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                    errorComponent={ErrorComponent}
                    loadingComponent={LoadingComponent}>
                    <RenderComponent showPolicyModal={showPolicyModal}>{children}</RenderComponent>
                </MsalAuthenticationTemplate>
            ) : (
                <GuestLayout>
                    <AppIntroduction />
                </GuestLayout>
            )}
        </>
    );
};

export default AppLayout;
