import { AlignValue, CursorValue, DashStyleValue } from 'highcharts';

const brandColor = '#ffe600';

const theme = {
    brandColor: brandColor,
    bodyBg: '#1a1a24',
    iconColor: '#FFF',
    progressLoaderBgColor: 'rgba(0, 0, 0, 0.75)',

    scrollBarBgColor: '#23232F',
    scrollBarThumbBgColor: 'rgba(145, 158, 171, 0.6)',
    scrollBarTrackBgColor: 'transparent',

    avatarBgColorList: '#1777cf,#922b73,#542ea5',
    avatarBgColor: '#1777cf',
    avatarInitialsIconColor: '#FFF',

    headerTitleColor: '#FFF',
    headerBgColor: '#2e2e38',

    sideBarBgColor: '#2e2e38',
    sideBarActiveColor: '#FFF',
    sideBarActiveBgColor: '#1a1a24',
    sideBarBorderWidth: 0,
    sideBarActiveBorderColor: brandColor,
    sideBarActiveBorderWidth: '0 2px',
    sideBarActiveBorderStyle: 'solid',

    tableOddRowBgColor: '#1a1a24',
    tableEvenRowBgColor: '#1e1e2a',

    tabNavigationColor: '#FFF',
    tabNavigationActiveColor: '#FFF',
    tabNavigationBgColor: 'transparent',
    tabNavigationActiveBgColor: 'transparent',
    tabNavigationBorderStyle: 'none',
    tabNavigationBorderWidth: '0',
    tabNavigationBorderRadius: '0',
    tabNavigationOutline: 'none',

    modalFooterBorderColor: '#2e2e3c',

    page: {
        left: '20px',
        right: '20px',
        top: '6px',
        bottom: '52px'
    },
    card: {
        horizontalPadding: '16px'
    },
    chart: {
        yAxisLabelColor: '#fff',
        xAxisLabelColor: '#fff',
        axisGridColor: '#464646',
        axisLabelColor: '#c4c4cd',
        font12PX: '12px',
        font14PX: '14px',
        font13PX: '13px',
        fontInterstate: `'EYInterstate', 'Segoe UI', sans-serif`,
        fontWeight400: 'var(--weight-regular)',
        wordSpacing: '1px',
        lineColor: '#464646',
        gridLineDashStyle: 'Solid' as DashStyleValue,
        alignRight: 'right' as AlignValue,
        cursorPointer: 'pointer',
        cursorDefault: 'default' as CursorValue
    },
    accordion: {
        bgColor: 'transparent',
        titleBgColor: '#23232F',
        padding: '20px 0 0'
    },
    white: '#fff',
    black: '#000',
    gray100: '#2e2e3c',
    yellow: '#ffe600',
    yellow2: '#ffe601',
    alternategray: '#c4c4cd',
    gray: '#747480',
    gray1: '#404854',
    filterBg: '#23232F',
    filterBgHover: '#282836',
    dropdownBorder: '#eaeaf2',
    headerHeight: 56,
    headerZIndex: 99,
    lightgray: '#f6f6fa',
    lightblack: '#1a1a24',
    darkgray: '#23232f',
    gray2: '#fafafc'
};

export default theme;
