import { MotifInput } from '../../../assets/lib/motif';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    testId?: string;
    id: string;
    type?: 'date' | 'email' | 'number' | 'text' | 'password' | 'tel';
    value: string | number;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    hideClearButton?: boolean;
    clearButtonTitle?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};
const Input: React.FC<InputProps> = ({
    testId = '',
    id,
    required,
    disabled,
    className,
    type = 'text',
    value,
    placeholder,
    hideClearButton = true,
    onChange,
    onFocus,
    onBlur,
    ...other
}: InputProps) => {
    return (
        <MotifInput
            {...other}
            data-test-id={testId}
            className={className}
            type={type}
            id={id}
            disabled={disabled}
            value={value}
            hideClearButton={hideClearButton}
            required={required}
            autoComplete="off"
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
};

export default Input;
