import styled from 'styled-components';

export const StyleSidbarWrapper = styled.div<{ isExpanded: boolean }>`
    width: ${(props): string => (props.isExpanded ? '282px' : '55px')};
    transition: width 0.5s;
`;

export const StyleSidbar = styled.div`
    position: fixed;
    .sidebar-wrapper {
        display: flex;
        overflow: hidden;
        margin: 0;
        padding: 0;
        min-height: 100vh;
    }
`;
