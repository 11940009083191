import styled from 'styled-components';

// take as props
interface StyledImageProps {
    width?: string;
    height?: string;
    objectFit?: string;
}

export const StyledImage = styled.img<StyledImageProps>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '100%'};
    object-fit: ${(props) => props.objectFit || 'cover'};
`;
