import { FC } from 'react';
import { MotifIcon } from '../../../assets/lib/motif';

type IconProps = React.HtmlHTMLAttributes<HTMLSpanElement> & {
    src: React.ReactNode;
    suppressEvent?: boolean;
    disabled?: boolean;
    testId?: string;
    className?: string;
};

const Icon: FC<IconProps> = ({ src, testId = '', className = '', ...props }: IconProps) => {
    return <MotifIcon {...props} src={src} data-test-id={testId} className={`icon ${className}`} />;
};

export default Icon;
