/**
 *  tenant?: string; //there might be a case where we need to introduce the tenant
    module?: string; //this is the entry point of out App ,2 modules
    main?: string; // the pages which will clicked from the side bar dashboard default
    subpage?: string; //overview | Project performance | Scrnario Outcomes
    childSubpage?: string; //chid routes of Subpages(Scrnario Outcomes) :prices | netowrk
 */
export interface IRoutingOptions {
    id?: string;
    tenant?: string;
    module?: string;
    main?: string;
    subpage?: string;
    childSubpage?: string;
    expanded?: string;
}

export const USER_ROUTES = {
    LOGIN: '/login',
    REGISTER: '/register'
};

export const INVESTOR_MODULE_ROUTES = { DASHBOARD: 'dashboard' };

export const ROUTES = {
    DEFAULT: '/',
    WILD: '*',
    TEST: '/test',
    NOT_FOUND: '/notfound',
    INVESTOR_MODULE: 'investor-module',
    USER: USER_ROUTES,
    INVESTOR_MODULE_ROUTES: INVESTOR_MODULE_ROUTES,
    CHART_GUIDE: 'chart-guide'
};
