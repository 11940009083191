import { ReactNode, ButtonHTMLAttributes } from 'react';
import { MotifHeader } from '../../../assets/lib/motif';
import styled from 'styled-components';

type HeaderProps = {
    fixed?: boolean;
    iconButton?: ReactNode | null;
    leftComponents?: ReactNode | null;
    iconsContainer?: ReactNode | null;
    logo?: ReactNode | null;
    menu?: ReactNode | null;
    menuRef?: () => void | Record<string, unknown>; // Record is used to represent empty object
    searchInput?: ReactNode | null;
    searchOpenCloseButtonProps?: Partial<ButtonHTMLAttributes<HTMLButtonElement>>;
    appHeaderName?: string;
    className?: string;
};

const Header = styled(({ className = '', ...props }: HeaderProps) => (
    <MotifHeader className={className} {...props} />
))`
    z-index: ${({ theme }) => theme.headerZIndex};

    .motif-header-company-container {
        align-items: flex-end;
    }

    .motif-header-row .motif-header-logo {
        display: block;
        height: 35px;
        bottom: 0;

        svg {
            padding: 0;
        }

        .motif-icon {
            position: relative;
            width: 32px;
            height: 32px;
            display: inline-block;
            bottom: -1px;
        }

        &::after {
            content: '';
            display: block;

            height: 35px;
            width: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .motif-header-app-name {
        position: relative;
        margin-left: 0px;
        font-weight: var(--weight-regular);
        font-size: 18px;
        margin-bottom: -3px;
    }

    .motif-header-vertical-navigation-open-button {
        height: 2rem !important;
        .motif-icon-button {
            height: 2.6rem !important;
        }
    }

    .motif-dropdown-item {
        .motif-avatar {
            width: 28px;
            height: 28px;
            margin-right: 10px;
        }
        img {
            margin-right: 10px;
        }
    }
`;

export default Header;
