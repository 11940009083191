import React, { useState } from 'react';
import { FOOTER, FOOTER_LEAGAL_SECTION } from '../../../core/base/const';
import FooterModal from './FooterModal/FooterModal';
import Footer from 'components/base/Footer';
import { Button } from 'components/base';
import Flex, { FLEX_ALIGN, FLEX_WRAP } from 'assets/styled/Flex';
import { StyleAppFooter } from './AppFooter.style';
import { RootState, useAppSelector } from 'core/store';

const AppFooter: React.FC = () => {
    const [modalType, setModalType] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);

    const showPolicyModal = useAppSelector(
        (state: RootState) => state?.userProfile?.showPolicyModal
    );

    const handleShowModal = (type: string) => {
        if (showPolicyModal) return;
        setModalType(type);
        setShowModal(true);
    };
    const footerYear = new Date().getFullYear();
    return (
        <StyleAppFooter>
            <FooterModal show={showModal} type={modalType} onClose={() => setShowModal(false)} />
            <Footer className="global-footer">
                <Flex gap={60} wrap={FLEX_WRAP.WRAP}>
                    <div className="global-footer__text">
                        <div>
                            {`Copyright © ${footerYear} EYGM Limited. All rights reserved. ${FOOTER_LEAGAL_SECTION}`}
                        </div>
                        <div className="global-footer__copy-right"></div>
                    </div>

                    <Flex
                        gap={32}
                        wrap={FLEX_WRAP.NO_WRAP}
                        justify={FLEX_ALIGN.FLEX_END}
                        align={FLEX_ALIGN.FLEX_START}
                        className="global-footer__right">
                        <Button
                            variant="text"
                            className="global-footer__links"
                            onClick={() => handleShowModal(FOOTER.termsOfUse)}>
                            {FOOTER.termsOfUse}
                        </Button>
                        <Button
                            variant="text"
                            className="global-footer__links"
                            onClick={() => handleShowModal(FOOTER.privacyNotice)}
                            disabled={showPolicyModal}>
                            {FOOTER.privacyNotice}
                        </Button>
                    </Flex>
                </Flex>
            </Footer>
        </StyleAppFooter>
    );
};

export default React.memo(AppFooter);
