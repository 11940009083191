import { combineReducers } from 'redux';
import { reducer as apiReducer, reducerPath as apiReducerPath } from '../api/api.base';
import appSetting from './appSetting.reducer';
import whatIfScenarios from './whatIfScenarios.reducer';
import filter from './filter.reducer';
import userProfile from './userProfile.reducer';

const rootReducer = combineReducers({
    appSetting,
    filter,
    whatIfScenarios,
    userProfile,
    [apiReducerPath]: apiReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
