import { FC } from 'react';
import React from 'react';
import { MotifVerticalNavigationHeaderButton } from '../../../../assets/lib/motif';

type VerticalNavigationHeaderButtonProps = {
    handleOnClick: () => void;
};

const VerticalNavigationHeaderButton: FC<VerticalNavigationHeaderButtonProps> = ({
    handleOnClick
}) => {
    return <MotifVerticalNavigationHeaderButton onClick={handleOnClick} />;
};

export default React.memo(VerticalNavigationHeaderButton);
