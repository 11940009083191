import { ReactElement, ReactChild } from 'react';
import { MotifVerticalNavigationMenuItem } from '../../../../../assets/lib/motif';

type SidebarMenuItemProps<TId = string> = {
    id: TId;
    icon?: ReactElement | null;
    className?: string;
    children: ReactChild;
    selected?: boolean;
    title?: string;
    onClick?: () => void;
};

const SidebarMenuItem = <TId,>({
    icon = null,
    children,
    className = '',
    selected = false,
    onClick,
    id,
    title,
    ...props
}: SidebarMenuItemProps<TId>) => {
    return (
        <MotifVerticalNavigationMenuItem
            {...props}
            className={className}
            id={`${id}`}
            icon={icon}
            selected={selected}
            onClick={onClick}
            label={title}>
            {children}
        </MotifVerticalNavigationMenuItem>
    );
};

export default SidebarMenuItem;
