import { MotifTabNavigation } from '../../../assets/lib/motif';

type TabBarProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
    variant?: 'default' | 'alt';
    className?: string;
    children?: React.ReactNode;
    testId?: string;
    defaultActiveKey?: number;
};

const TabBar: React.FC<TabBarProps> = ({
    variant = 'default',
    testId = '',
    children,
    className,
    defaultActiveKey = 0,
    ...props
}: TabBarProps) => {
    return (
        <MotifTabNavigation
            className={`tab-navigation-container ${className}`}
            {...props}
            defaultActiveKey={defaultActiveKey}
            variant={variant}
            data-test-id={testId}>
            {children}
        </MotifTabNavigation>
    );
};

export default TabBar;
