import styled from 'styled-components';

export const StyleAppFooter = styled.div`
    .global-footer {
        z-index: 99;
        position: relative;
        border: 0;
        padding: 1rem !important;
        height: auto !important;

        &__links {
            font-style: normal;
            font-weight: var(--weight-regular);
            font-size: 14px;
        }

        &__right {
            text-align: right;
        }

        &__text {
            text-align: left;
            flex: 2;
        }
        &__copy-right {
            width: 100%;
            text-align: right;
        }
    }
`;
