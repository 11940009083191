import { IRoutingOptions, colorPalettePool, motifColors } from 'core/base/const';
import { QueryKeyEnum } from 'core/base/enum';
import { sanitizeHtml } from './htmlUtil';

/**
 * Checks whether an arguments is null.
 *
 * @param The values to be checked.
 *
 * @returns true if any argument is null.
 */
export const isNull = function isNull<T>(...args: T[]): boolean {
    if (args.length === 0) {
        return true;
    }
    const len: number = args.length;
    for (let i = 0; i < len; i++) {
        const obj: T = args[i];
        if (obj === undefined || obj === null) {
            return true;
        }
    }
    return false;
};

/**
 * Checks whether all of the provided arguments are NOT null.
 *
 * @param values to be checked.
 *
 * @returns true if all arguments are NOT null.
 */
export const isNotNull = function isNotNull<T>(...args: T[]): boolean {
    return !isNull(...args); // forward args
};

/**
 * to serialize the query prams
 */
export const serializeQueryString = <T>(obj: T): string => {
    return JSON.stringify(obj);
};

export const removeHashFromURL = () => {
    let searchUrl = window.location.search;
    const urlParameter = window.location;
    if (
        (urlParameter.hash !== '' || urlParameter.hash !== null) &&
        !urlParameter.hash.includes('id_token')
    ) {
        searchUrl = urlParameter.search + urlParameter.hash;
        return searchUrl;
    } else {
        return searchUrl;
    }
};

export const sanitizeQueryString = (query: string): string => {
    if (!query) return '';
    return query.match(/^\?[a-z]+=/) ? query : `?${QueryKeyEnum.Query}=${query}`;
};

export const generateTabId = (tabRoutes: IRoutingOptions): string => {
    const formattedString = Object.values(tabRoutes).toString().replaceAll(',', '/');
    return `/${formattedString}`;
};

const colorPaletteKeys = Object.keys(colorPalettePool);

export const getColorPaletteKey = (dataIdx: number): string => {
    const key =
        colorPaletteKeys[
            ((dataIdx * 7) % colorPaletteKeys.length) +
                Math.trunc((dataIdx % colorPaletteKeys.length) / 7)
        ];
    return key;
};

export const getColorPaletteColor = (dataIdx: number): string => {
    const key = getColorPaletteKey(dataIdx);
    return colorPalettePool[key];
};

export const truncateTitle = (str: string, n: number, useWordBoundary?: any): string => {
    if (!str || str?.length <= n) {
        return str;
    }
    const subString = str.slice(0, n - 1); // the original check
    return (useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString) + '...';
};

export const getNameFromId = (data: { id: string; name: string }[], id: string) =>
    data?.find((item) => item.id === id)?.name;

export { sanitizeHtml };

export const getMotifColor = (index: number): string => {
    if (index >= motifColors.length) {
        return getColorPaletteColor(index);
    } else {
        return motifColors[index];
    }
};
