import { FC, HtmlHTMLAttributes } from 'react';
import { MotifProgressLoader } from '../../../assets/lib/motif';

type LoaderProps = HtmlHTMLAttributes<HTMLDivElement> & {
    show?: boolean;
    fullScreen?: boolean;
    className?: string;
};

const Loader: FC<LoaderProps> = ({
    show = true,
    fullScreen = false,
    className = 'global-loader',
    ...props
}: LoaderProps) => {
    return (
        <MotifProgressLoader
            {...props}
            show={show}
            className={className}
            fullscreen={fullScreen}
            data-test-id="loader-ui"
        />
    );
};

export default Loader;
