import { IRouteType } from 'components/custom/TabNav/ITabNav';
import { IFilterConfiguration } from 'core/base/type';
import { createContext } from 'react';

export type RouteManagerContextType = {
    scenarioDetailsList?: IFilterConfiguration;
    redirectTo: (
        paramsForReplace: {
            [key: string]: string | boolean | number | undefined;
        },
        queryString: string,
        routeType: IRouteType
    ) => void;
};

export const RouteManagerContext = createContext<RouteManagerContextType | null>(null);
