import { ReactNode, FC, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { RootState, useAppDispatch, useAppSelector } from 'core/store';
import { ROUTES } from 'core/base/const';
import { setSideBar } from 'core/store/appSetting.reducer';
import { setUserProfile } from 'core/store/userProfile.reducer';
import { SidebarContext } from '../../common/context';
import WindowService from '../../common/utils/window.utils';
import AppHeader from '../../components/custom/AppHeader';
import AppSidebar from '../../components/custom/AppSidebar';
import ErrorBoundary from '../../components/custom/ErrorBoundary/ErrorBoundary';
import AppFooter from '../../components/custom/AppFooter';
import { initializeSession } from 'core/service';
import { StyledAuthorizedLayout } from './AuthorizedLayout.style';
import { fetchLogout } from 'core/service/logout.service';

type AuthorizedLayoutProps = {
    children: ReactNode;
};

const AuthorizedLayout: FC<AuthorizedLayoutProps> = ({ children }) => {
    const { pathname } = useLocation();
    const { instance } = useMsal();
    const dispatch = useAppDispatch();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [hasError, setHasError] = useState(false);

    const { showSideBar } = useAppSelector((state: RootState) => state.appSetting);
    const logoutState = useAppSelector((state: RootState) => state?.userProfile?.logout);

    const account = instance.getActiveAccount();
    const sideBarContextInitialValue = useMemo(
        () => ({
            isExpanded,
            setIsExpanded
        }),
        [isExpanded]
    );
    const handleLogout =  async() => {
        await fetchLogout();
        const logoutRequest = {
            account: account ?? instance.getActiveAccount(),
            postLogoutRedirectUri: '/'
        };
        instance.logoutRedirect(logoutRequest);
    };

    useEffect(() => {
        initializeSession();
    }, []);

    useEffect(() => {
        if (account?.idTokenClaims) {
            const userData = {
                displayName: account?.name ?? '',
                email: account?.username,
                isExternal: false,
                profileImage: ''
            };
            dispatch(setUserProfile(userData));
        } else {
            handleLogout();
        }
    }, []);

    useEffect(() => {
        if (hasError) {
            WindowService.reloadPage();
        }

        if (pathname === ROUTES.DEFAULT) {
            dispatch(setSideBar(false));
        } else {
            dispatch(setSideBar(true));
        }
    }, [pathname]);

    useEffect(() => {
        if (logoutState) {
            handleLogout();
        }
    }, [logoutState]);

    const handleError = () => {
        setHasError(true);
    };

    return (
        <SidebarContext.Provider value={sideBarContextInitialValue}>
            <StyledAuthorizedLayout.AppContainer>
                <AppHeader />
                <StyledAuthorizedLayout.Main as="main">
                    {showSideBar && <AppSidebar />}
                    <StyledAuthorizedLayout.ContentWrapper>
                        <StyledAuthorizedLayout.Content>
                            <ErrorBoundary onError={handleError}>
                                <StyledAuthorizedLayout.Page as="section">
                                    {children}
                                </StyledAuthorizedLayout.Page>
                            </ErrorBoundary>
                        </StyledAuthorizedLayout.Content>
                        <AppFooter />
                    </StyledAuthorizedLayout.ContentWrapper>
                </StyledAuthorizedLayout.Main>
            </StyledAuthorizedLayout.AppContainer>
            <div id="modal"></div>
        </SidebarContext.Provider>
    );
};

export default AuthorizedLayout;
