import { createSlice } from '@reduxjs/toolkit';

export const appSettingInitialState = {
    showSideBar: true
};

const appSettingReducer = createSlice({
    name: 'app',
    initialState: appSettingInitialState,
    reducers: {
        setSideBar: (state, action) => {
            state.showSideBar = action.payload;
        }
    }
});

export const { setSideBar } = appSettingReducer.actions;
export default appSettingReducer.reducer;
