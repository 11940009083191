import React, { FC } from 'react';
import { HEADER } from '../../../core/base/const';
import { HeaderLogo } from '../../base/Header';
import Icon from '../../base/Icon';
import { defaultLogo } from 'assets/lib/motif/icons';
import { BASE_APP_URL } from '../../../app.constant';

const AppHeaderLogo: FC = () => {
    return (
        <HeaderLogo>
            <a className="motif-header-anchor" href={BASE_APP_URL}>
                <Icon src={defaultLogo} aria-hidden="true" />
                <span className="hide-for-accessibility">{HEADER.EY_LOGO_HOME}</span>
            </a>
        </HeaderLogo>
    );
};

export default React.memo(AppHeaderLogo);
