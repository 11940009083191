import React from 'react';
import { MotifTooltip } from '../../../assets/lib/motif';

export enum TooltipPlacement {
    AUTO = 'auto',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left'
}

export enum TooltipVariant {
    DEFAULT = 'default',
    ALT = 'alt'
}

export type TooltipProps = {
    id?: string;
    allowClick?: boolean;
    allowHover?: boolean;
    children: React.ReactChild;
    flip?: boolean;
    placement?: TooltipPlacement;
    content: React.ReactNode;
    variant?: TooltipVariant;
    hide?: boolean;
    hideArrow?: boolean;
    contentClassName?: string;
    hideCloseButton?: boolean;
    className?: string;
};

const Tooltip = ({
    id = 'tooltip',
    allowClick = false,
    allowHover = true,
    children,
    flip = true,
    placement = TooltipPlacement.AUTO,
    content,
    variant = TooltipVariant.ALT,
    hide = false,
    contentClassName = '',
    hideArrow = false,
    hideCloseButton = false,
    className = ''
}: TooltipProps) => (
    <MotifTooltip
        id={id}
        allowClick={allowClick}
        allowHover={allowHover}
        flip={flip}
        placement={placement}
        trigger={children}
        variant={variant}
        hide={hide}
        hideArrow={hideArrow}
        contentClassName={contentClassName}
        hideCloseButton={hideCloseButton}
        className={className}>
        {content}
    </MotifTooltip>
);

export default Tooltip;
