import styled from 'styled-components';

const Container = styled.div<{
    paddingTop?: string;
    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
}>`
    padding-top: ${({ paddingTop, theme }) => paddingTop || theme.page.top};
    padding-bottom: ${({ paddingBottom, theme }) => paddingBottom || theme.page.bottom};
    padding-left: ${({ paddingLeft, theme }) => paddingLeft || theme.page.left};
    padding-right: ${({ paddingRight, theme }) => paddingRight || theme.page.right};
`;
export default Container;
