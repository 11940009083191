import { ReactNode, FC } from 'react';

type GuestLayoutProps = {
    children: ReactNode;
};

const GuestLayout: FC<GuestLayoutProps> = ({ children }: GuestLayoutProps) => {
    return (
        <div className="guest-layout">
            <main className="guest-layout__contents">{children}</main>
        </div>
    );
};

export default GuestLayout;
