// Create image reusable react component.

import React from 'react';
import { StyledImage } from './Image.styled';

type ImageProps = {
    src?: string;
    alt?: string;
    className?: string;
    width?: string;
    height?: string;
    objectFit?: string;
    imageTestId?: string;
};

// send props to styled component
const Image: React.FC<ImageProps> = ({
    src,
    alt,
    className,
    width,
    height,
    objectFit,
    imageTestId
}) => (
    <StyledImage
        src={src}
        alt={alt}
        className={className}
        width={width}
        height={height}
        objectFit={objectFit}
        data-test-id={imageTestId}
    />
);
export default Image;
