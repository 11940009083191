export enum CHART_UNIT {
    billion = 'Billion',
    index = 'Index',
    million_dollar = 'Million $',
    output_dollar = 'Output $',
    percentage = '%',
    regions = 'Regions',
    dollar = '$',
    dollar_mwh = '$ / MWh',
    price_dollar_mwh = 'Price ($ / MWh)',
    gwh = 'GWh',
    mwh = 'MWh',
    twh = 'TWh',
    dollar_mw = '$/MW',
    time = 'Time',
    mw = 'MW'
}

export enum InChartFilterType {
    YEAR = 'year',
    INTERCONNECTOR = 'interconnector',
    REGION = 'region'
}

export const enum WidgetFilterBy {
    SCENARIO = 'scenario',
    INTERCONNECTOR = 'interconnector',
    REGION = 'region',
    PROJECT = 'project'
}

export const enum TableColumn {
    FINANCIAL_YEAR = 'Financial Year'
}
