// dev
// export const CLIENT_ID = 'fbd8f9ce-f4be-4b2a-9e92-db209ca93ddc';
// export const BASE_API_URL = 'https://emaas-bff-dev.sbp.eyclienthub.com/apigateway/api/v1';
// export const BASE_APP_URL = 'http://localhost:3000/';
// export const X_Tenant_Id = '16785d30-5b22-443d-b961-ae9e47c2df48';
// export const TENANT = '5b973f99-77df-4beb-b27d-aa0c70b8482c';

export const CLIENT_ID = 'fbd8f9ce-f4be-4b2a-9e92-db209ca93ddc';
export const BASE_API_URL = 'https://emaas-bff-dev.sbp.eyclienthub.com/apigateway/api/v1';
export const BASE_APP_URL = 'https://emaas-dev.sbp.eyclienthub.com/';
export const X_Tenant_Id = '16785d30-5b22-443d-b961-ae9e47c2df48';
export const TENANT = '5b973f99-77df-4beb-b27d-aa0c70b8482c';
