import styled from 'styled-components';

export enum FLEX_ALIGN {
    CENTER = 'center',
    FLEX_START = 'flex-start',
    FLEX_END = 'flex-end',
    SPACE_AROUND = 'space-around',
    SPACE_BETWEEN = 'space-between'
}

export enum FLEX_DIRECTION {
    COLUMN = 'column',
    ROW = 'row'
}

export enum FLEX_WRAP {
    WRAP = 'wrap',
    NO_WRAP = 'nowrap'
}

const Flex = styled.div<{
    align?: FLEX_ALIGN.CENTER | FLEX_ALIGN.FLEX_START | FLEX_ALIGN.FLEX_END;
    justify?:
        | FLEX_ALIGN.CENTER
        | FLEX_ALIGN.FLEX_START
        | FLEX_ALIGN.FLEX_END
        | FLEX_ALIGN.SPACE_AROUND
        | FLEX_ALIGN.SPACE_BETWEEN;
    gap?: number;
    wrap?: FLEX_WRAP.WRAP | FLEX_WRAP.NO_WRAP;
    direction?: FLEX_DIRECTION.COLUMN | FLEX_DIRECTION.ROW;
}>`
    display: flex;
    ${(props) => (props.align ? `align-items: ${props.align};` : null)};
    ${(props) => (props.justify ? `justify-content: ${props.justify};` : null)};
    ${(props) => (props.gap ? `gap: ${props.gap}px` : null)};
    ${(props) => (props.gap ? `flex-wrap: ${props.wrap}` : null)};
    ${(props) => (props.direction ? `flex-direction: ${props.direction}` : null)};
`;
export default Flex;
