import { createContext } from 'react';

export type SidebarContextType = {
    isExpanded: boolean;
    setIsExpanded: (value: boolean) => void;
};

const initialValues: SidebarContextType = {
    isExpanded: false,
    setIsExpanded: () => ''
};

export const SidebarContext = createContext(initialValues);
