import styled from 'styled-components';

export const StylePrivacy = styled.div`
    .main-container {
        .main-wrapper {
            display: flex;
            margin: 0rem 0 0 0;
            padding: 0;
            min-height: 100vh;

            .main-content {
                flex-grow: 1;
                padding: 0px 86px;
                width: 100%;
                margin-bottom: 0px;
                margin-top: 55px;
            }
        }
    }
    .wrapper-progressBar {
        width: 100%;
        .motif-modal-headline {
            font-size: 16px;
        }
        .policy-modal {
            padding: 8rem 20%;
            .motif-modal-body {
                max-height: calc(90vh - 100px);
            }
            .motif-modal-header {
                .motif-icon-button {
                    display: none;
                }
            }
        }
        .motif-modal-body,
        .motif-modal-footer {
            background-color: ${({ theme }) => theme.filterBg};
        }
        .motif-checkbox {
            margin-bottom: 1rem;
        }
        .motif-button-primary:focus {
            background-color: ${({ theme }) => theme.yellow};
            border-color: ${({ theme }) => theme.yellow};
        }
        .policy-error {
            padding-top: 1rem;
            text-align: center;
        }
        .footer-wrapper {
            width: 100%;
        }
        .agree-to-privacy {
            margin-top: -5px;
        }
    }
`;

export const LoaderStyle = styled.div`
    .loadIndicator {
        position: absolute;
        text-align: center;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-transform: uppercase;
    }
`;
