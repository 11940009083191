import styled from 'styled-components';
import { MotifButton } from '../../../assets/lib/motif';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: 'primary' | 'primary-alt' | 'secondary' | 'warn' | 'text' | 'text-alt';
    size?: 'medium' | 'large' | 'fluid' | null;
    disabled?: boolean;
    stepper?: 'prev' | 'next' | null;
    type?: 'submit' | 'reset' | 'button';
    children?: React.ReactNode;
    className?: string;
};

const Button = styled(
    ({
        variant = 'primary',
        size = null,
        disabled,
        stepper = null,
        type = 'submit',
        children,
        className = '',
        ...other
    }: ButtonProps) => (
        <MotifButton
            {...other}
            variant={variant}
            size={size}
            disabled={disabled}
            stepper={stepper}
            type={type}
            className={className}>
            {children}
        </MotifButton>
    )
)<{
    variant?: string;
}>`
    //Custom styles
    /* background: ${(props) => (props.variant == 'secondary' ? '#BF4F74' : 'white')};
    color: ${(props) => (props.variant == 'secondary' ? 'white' : '#BF4F74')}; */
`;

export default Button;
