export const WHAT_IF_SCENARIOS = {
    overview:
        'The What If Scenarios analysis enables users to adjust key variables in the model to analyze the impact on the market and their projects. A simulation can be built and scheduled that includes multiple changes to the base reference case.',
    sensitivityCardHeading: 'Click on a sensitivity to build the simulation',
    sensitivityAddNewCard:
        'Select another sensitivity to add to the simulation. If the simulation is complete save to Scenario Library.',
    createNewScenarioTitle: 'Click to create a new Scenario to build the simulation',
    createNewScenarioButton: 'Create New Scenario'
};

export const SENSITIVITY_FILTER_LABEL = {
    scenario: 'Select Scenario *',
    simulation: 'Select Simulation',
    region: 'Select Region *',
    technology: 'Select Technology *',
    project: 'Select the Project to modify *',
    augmentation: 'Select Augmentation Sets',
    modifyAugmentationTimings: 'Modify Augmentation Timings'
};

export const BUTTONS_LABEL = {
    save: 'Save',
    close: 'Close',
    cancel: 'Cancel'
};

export const CALENDER_LABEL = {
    currentCommissioningDate: 'Current Commissioning Date',
    currentRetirementDate: 'Current Retirement Date',
    newCommissioningDate: 'Select New Commissioning Date',
    newRetirementDate: 'Select New Retirement Date'
};

export const SAVE_SIMULATION = {
    simulationModelTitle: 'Save Simulation',
    simulationNameField: 'Simulation Name',
    simulationDescriptionField: 'Description',
    simulationDescriptionPlaceholder: 'Enter a description for this simulation'
};

export const DELETE_SCENARIO = {
    deleteScenario: 'Delete Scenario',
    deleteScenarioMessage: 'Are you sure you want to delete this scenario?',
    deleteScenarioConfirm: 'Delete',
    deleteScenarioCancel: 'Cancel'
};

export const DELETE_MODAL = {
    deleteHeader: 'Delete!',
    deleteMessageLineOne: 'This will permanently delete from the system,',
    deleteMessageLineTwo: `Click 'OK' to proceed or 'Cancel' to return`,
    deleteConfirm: 'OK',
    deleteCancel: 'Cancel'
};

export const SIMULATION_LIST_LABELS = {
    currentValues: 'Current Values',
    newValues: 'New Values',
    simulationCommissioningDate: 'Commissioning Date',
    simulationNewCommissioningDate: 'New Commissioning Date',
    simulationRetirementDate: 'Retirement Date',
    simulationNewRetirementDate: 'New Retirement Date',
    simulationAugmentationTiming: 'Augmentation Timing',
    simulationNewAugmentationTiming: 'New Augmentation Timing',
    simulationDescription: 'Description',
    simulationDate: 'Date',
    simulationAction: 'Action',
    simulationEdit: 'Edit',
    simulationDelete: 'Delete',
    simulationTitle: 'Simulation 001 - NEM Market Reference case - Simulation Title',
    simulationStatus: 'Status: ',
    latitude: 'Latiitude',
    longitude: 'Longitude',
    namePlateCapacity: 'Nameplate Capacity (MW)',
    proposedCommissioningDate: 'Proposed Commissioning Date',
    capacityFactor: 'Trace Capacity Factor',
    traceLocation: 'Trace Location',
    busName: 'Bus Name',
    busNumber: 'Bus Number',
    voltage: 'Voltage (KV)'
};

export const PROJECT_MODAL_LABELS = {
    header: 'Modify Generator Schedule',
    referenceCase: 'Reference Case *',
    title: 'Sensitivity Title',
    publishDate: 'Publish Date',
    description: 'Description',
    addScenarioHeader: 'Create New Scenario',
    editScenarioHeader: 'Save Simulation',
    scenario: 'Select Parent Scenario *'
};

export const ERROR_MESSAGES = {
    commissionGreaterThanRetirement:
        'The retirement date cannot be earlier than the commissioning date of a unit.',
    titleConflict: 'Scenario with this title already exists.',
    augmentationTimeError:
        'The new augmentation time cannot be start date of current financial year.',
    titleLengthError: 'Scenario title can not be more than 100 characters.',
    descriptionLengthError: 'Scenario description can not be more than 500 characters.',
    scenarioTitleFormatError:
        'Reference case or Scenario name should start with alphanumeric and can contain special characters like space, hyphen and underscore only.',
    projectTitleError:
        'Project name should start with alphanumeric and can contain special characters like space, hyphen, comma and underscore only.',
    descriptionFormatError:
        'Input is invalid. Please enter only alphanumeric characters, spaces, hyphens (-), underscores (_), commas (,) or dots (.).',
    genericMessage: 'Something went wrong. Please try again later.',
    nameLengthError: 'Project Name can not be more than 255 characters.',
    latitudeCoordinatesError: 'Latitude value shoud be from -90 to 90 upto 3 decimal places only.',
    longitudeCoordinatesError:
        'Longitude value shoud be from -180 to 180 upto 3 decimal places only.',
    plateCapacityNegativeError: 'Please enter a number greater than 0.',
    plateCapacityDecimalError: 'Please enter a number upto 2 decimal places only.',
    plateCapacityDigitError: 'Please enter a number upto 10 digits (without decimal places) only.'
};

export const getAgumentationTimingErrorMessage = (
    selectedSetNo: number,
    previousSetNumber: number,
    nextSetNumber: number = 0,
    errorNumber: number = -1
) => {
    let msg = '';
    if (errorNumber === 0) {
        msg = `Generator/Facility mapping error - Please select an earlier date for Augmentation Set ${selectedSetNo}`;
    } else if (previousSetNumber === 0) {
        msg = `Augmentation timing for Set ${selectedSetNo} should not be greater than set ${nextSetNumber}`;
    } else if (nextSetNumber === 0) {
        msg = `Augmentation timing for Set ${selectedSetNo} should not be less than set ${previousSetNumber} `;
    } else {
        msg = `Augmentation timing for Set ${selectedSetNo} should not be less than set ${previousSetNumber} or greater than set ${nextSetNumber}`;
    }
    return msg;
};

export const START_DATE_FINANCIAL = {
    startDate: new Date(new Date().getFullYear(), 3, 1)
};

export const SCENARIO_FIELD_DISPLAY_LABEL = {
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    PARENT_CASE: 'Parent Case',
    PUBLISHED_DATE: 'Published Date',
    CREATED_BY: 'Created By'
};

export const SCENARIO_TITLE_CHAR_LIMIT = 100;
export const SCENARIO_DESCRIPTION_CHAR_LIMIT = 500;
export const CUSTOM_PROJECT_CHAR_LIMIT = 255;
export const SCENARIO_TITLE_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9\s\-_]*$/;
export const DESCRIPTION_REGEX = /^[a-zA-Z0-9\s\-,_.]*$/;
export const PROJECT_TITLE_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9\s\-,_]*$/;
export const NUMBER_REGEX = /^-?\d*\.?\d*$/;
export const LATTITUDE_REGEX = /^-?(90(\.0+)?|[1-8]?[0-9](\.[0-9]{1,3})?)$/;
export const LONGITUDE_REGEX = /^-?(180(\.0+)?|1[1-7]\d(\.[0-9]{1,3})?|[1-9]?\d(\.[0-9]{1,3})?)$/;
export const LATITUDE_MAX = 90;
export const LATITUDE_MIN = -90;
export const LONGITUDE_MAX = 180;
export const LONGITUDE_MIN = -180;
export const LAT_LONG_DECIMAL = 3;
export const MINUS = '-';
export const PLATE_CAPACITY_DIGIT_LIMIT = 10;

export const CUSTOM_PROJECT_LABELS = {
    PROJECT_NAME: 'Enter Project Name',
    COMMISSIONING_DATE: 'Proposed Commissioning Date *',
    LATITUDE: 'Enter Latitude (decimal degrees - upto 3 decimals)',
    LONGITUDE: 'Enter Longitude (decimal degrees - upto 3 decimals)',
    TRACES_BY_DISTANCE: 'Top 10 traces by closest distance',
    CLOSEST_BUSES: 'Top 10 closest buses',
    NAME_PLATE_CAPACITY: 'Name plate Capacity (MW)',
    NAME_PLATE_CAPACITY_LABEL: 'Enter Name plate capacity (2 decimal places)',
    ADD_PROJECT_HEADER: 'Add My Custom Project - ',
    EDIT_PROJECT_HEADER: 'Edit My Custom Project - '
};
export const CUSTOM_PROJECT_BUTTONS = {
    SAVE_PROJECT: 'Save Project',
    CLOSE: 'Close',
    BACK: 'Back',
    NEXT: 'Next'
};

export const CUSTOM_PROJECT_HEADERS = {
    LOCATION: 'Location',
    CAPACITY_FACTOR: 'Capacity Factor',
    DISTANCE: 'Distance (km)',
    BUS_NUMBER: 'Bus Number',
    BUS_NAME: 'Bus Name',
    VOLTAGE: 'Voltage (KV)'
};

export const CUSTOM_PROJECT_MODAL_PAGE_NUMBER = {
    PAGE_ONE: 1,
    PAGE_TWO: 2
};

export const SIMULATION_TABLE_LABELS = {
    SENSITIVITY_ONE: 'Sensitivity 1',
    GENERATOR_MODULE: 'Generator Schedule',
    SENSITIVITY_TWO: 'Sensitivity 2',
    AUGMENTATION_TIMING: 'Augmentation Timing',
    SENSITIVITY_THREE: 'Sensitivity 3',
    REGION: 'Region: ',
    TECHNOLOGY: 'Technology: '
};
