import React, { FC, useContext } from 'react';
import { Header, VerticalNavigationHeaderButton } from '../../base/Header';
import { HEADER } from '../../../core/base/const';
import AppHeaderLogo from './AppHeaderLogo';
import { StyledAppHeader } from './AppHeader.style';
import AppHeaderProfileCard from './AppHeaderProfileCard';
import { SidebarContext } from '../../../common/context';
import { RootState, useAppSelector } from 'core/store';

const AppHeader: FC = () => {
    const { isExpanded, setIsExpanded } = useContext(SidebarContext);

    const { showSideBar } = useAppSelector((state: RootState) => state.appSetting);

    const handleToggle = (): void => {
        setIsExpanded(!isExpanded);
    };
    return (
        <StyledAppHeader>
            <Header
                fixed
                logo={<AppHeaderLogo />}
                appHeaderName={HEADER.APP_HEADER_NAME}
                leftComponents={
                    showSideBar && <VerticalNavigationHeaderButton handleOnClick={handleToggle} />
                }
                menu={
                    <div className="motif-header-profile-card">
                        <AppHeaderProfileCard />
                    </div>
                }
            />
        </StyledAppHeader>
    );
};

export default React.memo(AppHeader);
