import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getMotifColor } from 'common/utils';
import { createSelectedScenarioObject } from 'common/utils/filter.utils';
import { CAPABILITIES_TOGGLE_VIEW, MAX_SCENARIO_SELECTED } from 'core/base/const';
import { FilterEnum } from 'core/base/enum';
import {
    IFilterState,
    IFilterConfiguration,
    IFilterGeneric,
    IRegionFilter,
    IPreSelectedFilters,
    IErrors,
    MultiApiConfig,
    IInchartFilterPayload
} from 'core/base/type';
import { IGenericObject } from 'core/base/type/IGenericObject';

export const filterInitialState: IFilterState = {
    currentFilters: [],
    apiPayloadKeys: [FilterEnum.SCENARIO],
    scenario: {
        selected: '',
        currentSelection: '',
        data: []
    },
    multiscenario: {
        selected: [],
        currentSelection: [],
        data: [],
        showToast: false
    },
    technology: {
        selected: [],
        currentSelection: [],
        data: []
    },
    region: {
        selected: [],
        currentSelection: [],
        data: []
    },
    project: {
        selected: null,
        currentSelection: null
    },
    singleTechnology: {
        selected: [],
        currentSelection: [],
        data: []
    },
    singleRegions: {
        selected: [],
        currentSelection: [],
        data: []
    },
    interconnector: {
        selected: [],
        currentSelection: [],
        data: []
    },
    //To-do: To avoid type error added this below property
    transmissionLine: {
        selected: [],
        currentSelection: [],
        data: []
    },
    inChartFilter: [],
    loader: {
        scenario: true,
        technology: true,
        region: true,
        interconnector: true,
        singleRegions: true,
        singleTechnology: true,
        capabilitiesRadioToggle: false,
        capabilitiesRadioToggleSelect: false,
        multiscenario: true,
        inChartFilter: true
    },
    errors: {
        scenario: false,
        technology: false,
        region: false,
        interconnector: false,
        singleRegions: false,
        singleTechnology: false,
        capabilitiesRadioToggle: false,
        capabilitiesRadioToggleSelect: false,
        multiscenario: false,
        inChartFilter: false
    },
    filterDDState: {
        region: false,
        technology: false,
        interconnector: false,
        singleRegions: false,
        singleTechnology: false,
        project: false,
        multiscenario: false,
        inChartFilter: false
    },
    apiPayload: {},
    capabilitiesRadioToggleSelect: {
        currentSelection: CAPABILITIES_TOGGLE_VIEW.installedCapacityAndGenerationMix.id
    },
    scenarioIdfromQS: '',
    yearAPIsuccess: true
};

const createFiltersObject = (
    actionObj: Array<IFilterGeneric>
): { updatedData: Array<IFilterGeneric>; selectedData: Array<string> } => {
    const updatedData: Array<IFilterGeneric> = [];
    const selectedData: Array<string> = [];
    if (actionObj) {
        actionObj?.map((values, index) => {
            updatedData?.push({
                name: values?.name,
                id: String(values?.id),
                color: getMotifColor(index)
            });
            selectedData?.push(String(values?.id));
        });
    }

    return { updatedData, selectedData };
};

export function createScenarioObject(
    actionObj: Array<IFilterGeneric>,
    level = 0
): Array<IFilterGeneric> {
    const updatedScenarioData: Array<IFilterGeneric> = [];
    let newIndex = 0;
    createScenarioSubObject(actionObj, level);
    function createScenarioSubObject(actionObj: Array<IFilterGeneric>, level = 0) {
        if (actionObj) {
            actionObj?.forEach((values) => {
                updatedScenarioData?.push({
                    name: values?.name,
                    id: String(values?.id),
                    level: level,
                    color: getMotifColor(newIndex)
                });
                newIndex++;
                if (values?.data && values?.data?.length > 0) {
                    createScenarioSubObject(values?.data, level + 1);
                }
            });
        }
    }
    return updatedScenarioData;
}

export function getMultiScenarioColor(
    actionObj: Array<IFilterGeneric>,
    level = 0
): Array<IFilterGeneric> {
    let newIndex = 0;
    const newreturObj = createScenarioSubObject(actionObj, level);
    function createScenarioSubObject(
        actionObj: Array<IFilterGeneric>,
        level = 0
    ): Array<IFilterGeneric> | undefined {
        if (actionObj) {
            const updateArray: Array<IFilterGeneric> = [];
            actionObj?.forEach((values) => {
                const newObject: IFilterGeneric = {
                    name: values?.name,
                    id: String(values?.id),
                    level: level,
                    color: getMotifColor(newIndex)
                };
                newIndex++;
                if (values.data && values.data.length > 0) {
                    newObject.data = createScenarioSubObject(values.data, level + 1);
                }

                updateArray.push(newObject);
            });

            return updateArray;
        }
    }
    return newreturObj as Array<IFilterGeneric>;
}

export const createTechFilter = (
    payload: any[],
    selectedRegionData: Array<string>,
    technologyData: Array<IFilterGeneric> = []
) => {
    const updatedData: Array<IFilterGeneric> = [];
    const selectedData: Array<string> = [];
    if (technologyData?.length) {
        if (payload) {
            payload?.map((values) => {
                if (selectedRegionData?.includes(String(values?.id))) {
                    values?.technology?.map((tech: { name: string; id: string }) => {
                        const findFlag = technologyData.find(
                            (x: { name: string; id: string }) => String(x?.id) === String(tech?.id)
                        ) as {
                            name: string;
                            id: string;
                            colorCode: string;
                            orderId: string;
                        };
                        if (!selectedData.includes(String(tech?.id)) && findFlag) {
                            updatedData?.push({
                                name: tech?.name,
                                id: String(tech?.id),
                                color: (findFlag as { colorCode: string }).colorCode,
                                orderId: String((findFlag as { orderId: string }).orderId)
                            });
                            selectedData?.push(String(tech?.id));
                        }
                    });
                }
            });
        }
    }

    updatedData.sort((a, b) => {
        if (Number(a.orderId) > Number(b.orderId)) return 1;
        if (Number(a.orderId) < Number(b.orderId)) return -1;
        return 0;
    });

    return { updatedData, selectedData };
};

/* DropDown (Open/Close) state management */
const setFilterState = (state: IFilterState, value: string) => {
    const ddState: IGenericObject = { ...state.filterDDState };
    for (const key in ddState) {
        const index = value?.toLowerCase();
        if (key === index) {
            ddState[key] = !ddState[key];
        } else {
            ddState[key] = false;
        }
    }
    return ddState;
};

const filterReducer = createSlice({
    name: 'filter',
    initialState: filterInitialState,
    reducers: {
        setCurrentFilters: (state, action: PayloadAction<Array<FilterEnum>>) => {
            state.currentFilters = action.payload;
        },
        setApiPayloadKeys: (state, action: PayloadAction<FilterEnum[]>) => {
            // reset the payload
            state.apiPayloadKeys = action.payload;
            // Apply Api Payload
            const newApiPayload: MultiApiConfig = {};
            action.payload.forEach((key) => {
                if (
                    key !== FilterEnum.COMPARISON_CAPABILITIES &&
                    key !== FilterEnum.TRANSMISSION_LINE &&
                    key !== FilterEnum.SINGLE_REGIONS &&
                    key !== FilterEnum.SINGLE_TECHNOLOGY &&
                    key !== FilterEnum.PROJECT &&
                    key !== FilterEnum.MULTI_SCENARIO
                ) {
                    Object.assign(newApiPayload, { [key]: state[key].currentSelection });
                } else if (key === FilterEnum.PROJECT) {
                    Object.assign(newApiPayload, {
                        [key]: String(state[key].currentSelection?.id)
                    });
                } else if (key === FilterEnum.SINGLE_REGIONS) {
                    Object.assign(newApiPayload, {
                        [FilterEnum.REGIONS]: String(state[key].currentSelection[0])
                    });
                } else if (key === FilterEnum.SINGLE_TECHNOLOGY) {
                    Object.assign(newApiPayload, {
                        [FilterEnum.TECHNOLOGY]: String(state[key].currentSelection[0])
                    });
                } else if (key === FilterEnum.MULTI_SCENARIO) {
                    Object.assign(newApiPayload, {
                        [FilterEnum.SCENARIO]: state[key].currentSelection
                    });
                }
            });
            state.apiPayload = newApiPayload;
        },
        setScenario: (state, action: PayloadAction<IFilterConfiguration>) => {
            if (action?.payload?.scenarioData) {
                let scenarioId = '';
                if (action?.payload?.scenarioID && action?.payload?.scenarioID !== '') {
                    scenarioId = String(action?.payload?.scenarioID);
                } else {
                    if (action?.payload?.scenarioData[0] && action?.payload?.scenarioData[0]?.id) {
                        scenarioId = String(action?.payload?.scenarioData[0]?.id);
                    }
                }

                state.scenario.selected = scenarioId;
                state.scenario.currentSelection = scenarioId;
                state.scenario.data = createScenarioObject(action?.payload?.scenarioData);
                state.apiPayload = {
                    ...state.apiPayload,
                    ...{ [FilterEnum.SCENARIO]: scenarioId }
                };
                state.loader.scenario = false;
            }
        },
        setMultiScenario: (state, action: PayloadAction<IFilterConfiguration>) => {
            if (action?.payload?.scenarioData && action?.payload?.scenarioData[0]) {
                const scenarioIdArray = createSelectedScenarioObject([
                    action?.payload?.scenarioData[0]
                ]);
                state.multiscenario.selected = [];
                state.multiscenario.currentSelection = [];
                state.multiscenario.data = getMultiScenarioColor(action?.payload?.scenarioData);
                state.apiPayload = {
                    ...state.apiPayload,
                    ...{ [FilterEnum.MULTI_SCENARIO]: scenarioIdArray }
                };
                state.loader.multiscenario = false;
            }
        },
        setMultiScenarioOnParentSelect: (state, action: PayloadAction<string>) => {
            const selectedScenario = action?.payload;
            state.multiscenario.selected = [String(selectedScenario)];
            state.multiscenario.showToast = false;
            state.apiPayload = {
                ...state.apiPayload,
                ...{ [FilterEnum.MULTI_SCENARIO]: [selectedScenario] }
            };
        },
        setMultiScenarioOnChildSelect: (state, action: PayloadAction<string>) => {
            const selectedMultiScenario = [...state.multiscenario.selected];
            const index = selectedMultiScenario.indexOf(String(action?.payload));
            if (index < 0) {
                if (selectedMultiScenario.length < MAX_SCENARIO_SELECTED) {
                    selectedMultiScenario.push(String(action?.payload));
                    state.multiscenario.showToast = false;
                } else {
                    state.multiscenario.showToast = true;
                }
            } else {
                selectedMultiScenario.splice(index, 1);
                if (selectedMultiScenario.length < MAX_SCENARIO_SELECTED) {
                    state.multiscenario.showToast = false;
                }
            }
            state.multiscenario.selected = selectedMultiScenario;
        },
        setMultiScenarioToastClose: (state) => {
            state.multiscenario.showToast = false;
        },
        setSelectedScenario: (state, action: PayloadAction<string>) => {
            state.scenario.selected = String(action?.payload);
            // Reset Filter Dropdown state
            state.filterDDState.region = false;
            state.filterDDState.technology = false;
            state.filterDDState.interconnector = false;
            state.filterDDState.singleRegions = false;
            state.filterDDState.singleTechnology = false;
            state.filterDDState.project = false;
            state.filterDDState.multiscenario = false;
        },
        setSelectedTechnology: (state, action: PayloadAction<Array<string>>) => {
            state.technology.selected = action?.payload;
        },
        setTechnology: (state, action: PayloadAction<IRegionFilter>) => {
            const { data, isRegionAndTechnologyFilter, selectedRegions, technologyData } =
                action?.payload;
            const hasNoData = state.technology.data.length === 0;
            if (isRegionAndTechnologyFilter && selectedRegions && selectedRegions?.length > 0) {
                const finalTechData = createTechFilter(data, selectedRegions, technologyData);
                state.technology.data = finalTechData?.updatedData;
                state.technology.selected = finalTechData?.selectedData;
                state.technology.currentSelection = finalTechData?.selectedData;
                if (hasNoData && state.apiPayloadKeys.includes(FilterEnum.TECHNOLOGY)) {
                    state.apiPayload = {
                        ...state.apiPayload,
                        ...{ [FilterEnum.TECHNOLOGY]: finalTechData?.selectedData }
                    };
                }
                state.loader.technology = false;
            }
        },
        setTechnologyOnSelectAll: (state, action: PayloadAction<boolean>) => {
            if (action?.payload) {
                state.technology.selected = state.technology.data.map((item) => item?.id);
            } else {
                state.technology.selected = [];
            }
        },
        setRegions: (state, action: PayloadAction<IRegionFilter>) => {
            const { data } = action?.payload;
            const hasNoData = state.region.data.length === 0;
            const finalData = createFiltersObject(data);
            state.region.data = finalData?.updatedData;
            state.region.selected = finalData?.selectedData;
            state.region.currentSelection = finalData?.selectedData;
            if (hasNoData && state.apiPayloadKeys.includes(FilterEnum.REGIONS)) {
                state.apiPayload = {
                    ...state.apiPayload,
                    ...{ [FilterEnum.REGIONS]: finalData?.selectedData }
                };
            }
            state.loader.region = false;
        },
        setSelectedRegions: (state, action: PayloadAction<IRegionFilter>) => {
            const { data, updatedSelectedRegions, isRegionAndTechnologyFilter } = action?.payload;
            if (updatedSelectedRegions) {
                state.region.selected = updatedSelectedRegions;
                if (isRegionAndTechnologyFilter) {
                    const finalTechData = createTechFilter(data, updatedSelectedRegions);
                    state.technology.data = finalTechData?.updatedData;
                    state.technology.selected = finalTechData?.selectedData;
                }
            }
        },
        setRegionsOnSelectAll: (state, action: PayloadAction<IRegionFilter>) => {
            const { data, flag, isRegionAndTechnologyFilter } = action?.payload;
            if (flag) {
                const updatedSelectedRegions = state.region.data.map((item) => item?.id);
                state.region.selected = updatedSelectedRegions;
                if (isRegionAndTechnologyFilter) {
                    const finalTechData = createTechFilter(data, updatedSelectedRegions);
                    state.technology.data = finalTechData?.updatedData;
                    state.technology.selected = finalTechData?.selectedData;
                }
            } else {
                state.region.selected = [];
                if (isRegionAndTechnologyFilter) {
                    state.technology.data = [];
                    state.technology.selected = [];
                }
            }
        },
        setInterConnector: (state, action: PayloadAction<Array<IFilterGeneric>>) => {
            const finalData = createFiltersObject(action?.payload);
            const hasNoData = state.interconnector.data.length === 0;
            state.interconnector.data = finalData?.updatedData;
            state.interconnector.selected = finalData?.selectedData;
            state.interconnector.currentSelection = finalData?.selectedData;
            if (hasNoData && state.apiPayloadKeys.includes(FilterEnum.INTERCONNECTOR)) {
                state.apiPayload = {
                    ...state.apiPayload,
                    ...{ [FilterEnum.INTERCONNECTOR]: finalData?.selectedData }
                };
            }
            state.loader.interconnector = false;
        },
        setSelectedInterConnector: (state, action: PayloadAction<Array<string>>) => {
            state.interconnector.selected = action?.payload;
        },
        setInterConnectorOnSelectAll: (state, action: PayloadAction<boolean>) => {
            if (action?.payload) {
                state.interconnector.selected = state.interconnector.data.map((item) => item?.id);
            } else {
                state.interconnector.selected = [];
            }
        },
        setFilterDDState: (state, action: PayloadAction<string>) => {
            state.filterDDState = setFilterState(state, action?.payload);
        },
        resetFilters: (state) => {
            // Reset currentFilters
            state.currentFilters = [];

            // Reset Scenario with default value
            state.scenario.selected = '';
            state.scenario.currentSelection = '';
            state.loader.scenario = true;

            // Reset Multi Scenario with default value
            state.multiscenario.selected = [];
            state.multiscenario.currentSelection = [];
            state.multiscenario.data = [];
            state.multiscenario.showToast = false;
            state.loader.multiscenario = true;

            // Reset Regions
            state.region.data = [];
            state.region.selected = [];
            state.region.currentSelection = [];
            state.loader.region = true;

            // Reset Technology
            state.technology.data = [];
            state.technology.selected = [];
            state.technology.currentSelection = [];
            state.loader.technology = true;

            // Reset Interconnector
            state.interconnector.data = [];
            state.interconnector.selected = [];
            state.interconnector.currentSelection = [];
            state.loader.interconnector = true;

            // Reset singleRegions
            state.singleRegions.data = [];
            state.singleRegions.selected = [];
            state.singleRegions.currentSelection = [];
            state.loader.singleRegions = true;

            // Reset Interconnector
            state.singleTechnology.data = [];
            state.singleTechnology.selected = [];
            state.singleTechnology.currentSelection = [];
            state.loader.singleTechnology = true;

            // Reset Filter Dropdown state
            state.filterDDState.region = false;
            state.filterDDState.technology = false;
            state.filterDDState.interconnector = false;
            state.filterDDState.singleRegions = false;
            state.filterDDState.singleTechnology = false;
            state.filterDDState.project = false;
            state.filterDDState.multiscenario = false;

            // Reset Project
            state.project.selected = null;
            state.project.currentSelection = null;

            state.apiPayload = {};
            state.apiPayloadKeys = [FilterEnum.SCENARIO];

            state.errors.scenario = false;
            state.errors.technology = false;
            state.errors.region = false;
            state.errors.interconnector = false;
            state.errors.singleRegions = false;
            state.errors.singleTechnology = false;
            state.errors.multiscenario = false;
        },
        resetView: (state) => {
            state.capabilitiesRadioToggleSelect.currentSelection =
                CAPABILITIES_TOGGLE_VIEW.installedCapacityAndGenerationMix.id;
        },
        applyFilters: (state, action: PayloadAction<IFilterState>) => {
            // Set Scenario with selected value
            state.scenario.currentSelection = String(action?.payload?.scenario?.selected);

            // Set Multi Scenario with selected value
            state.multiscenario.currentSelection = action?.payload?.multiscenario?.selected;
            state.multiscenario.showToast = false;

            // Set Regions with selected value
            state.region.currentSelection = action?.payload?.region?.selected;

            // Set Technology
            state.technology.currentSelection = action?.payload?.technology?.selected;

            // set Interconnector
            state.interconnector.currentSelection = action?.payload?.interconnector?.selected;

            // set singleRegions
            state.singleRegions.currentSelection = action?.payload?.singleRegions?.selected;

            // set singleRegions
            state.singleTechnology.currentSelection = action?.payload?.singleTechnology?.selected;

            // Reset Filter Dropdown state
            state.filterDDState.region = false;
            state.filterDDState.technology = false;
            state.filterDDState.interconnector = false;
            state.filterDDState.singleRegions = false;
            state.filterDDState.singleTechnology = false;
            state.filterDDState.project = false;
            state.filterDDState.multiscenario = false;

            // set Project
            state.project.currentSelection = action?.payload?.project?.selected;

            // Apply Api Payload
            const newApiPayload: MultiApiConfig = {};
            state.apiPayloadKeys.forEach((key) => {
                switch (key) {
                    case FilterEnum.SINGLE_REGIONS:
                        newApiPayload[FilterEnum.REGIONS] = action?.payload[key]?.selected[0];
                        break;
                    case FilterEnum.MULTI_SCENARIO:
                        newApiPayload[FilterEnum.SCENARIO] = action?.payload[key]?.selected;
                        break;
                    case FilterEnum.SINGLE_TECHNOLOGY:
                        newApiPayload[FilterEnum.TECHNOLOGY] = action?.payload[key]?.selected[0];
                        break;
                    case FilterEnum.PROJECT:
                        newApiPayload[key] = String(action?.payload[key]?.selected?.id);
                        break;
                    // To avoid typescript error added COMPARISON_CAPABILITIES
                    case FilterEnum.COMPARISON_CAPABILITIES:
                        break;
                    // To avoid typescript error added TRANSMISSION_LINE
                    case FilterEnum.TRANSMISSION_LINE:
                        break;
                    default:
                        newApiPayload[key] = action?.payload[key]?.selected;
                        break;
                }
            });
            state.apiPayload = newApiPayload;
        },
        setPreSelectedFilters: (state, action: PayloadAction<IPreSelectedFilters>) => {
            if (action?.payload?.scenario) {
                state.scenario.selected = action?.payload?.scenario;
                state.scenario.currentSelection = action?.payload?.scenario;
                state.apiPayload = {
                    ...state.apiPayload,
                    scenario: action?.payload?.scenario
                };
            }

            if (action?.payload?.multiscenario && action?.payload?.multiscenario?.length > 0) {
                state.multiscenario.selected = action?.payload?.multiscenario;
                state.multiscenario.currentSelection = action?.payload?.multiscenario;
                state.apiPayload = {
                    ...state.apiPayload,
                    multiscenario: action?.payload?.multiscenario
                };
            }

            if (action?.payload?.region && action?.payload?.region?.length > 0) {
                state.region.selected = action?.payload?.region;
                state.region.currentSelection = action?.payload?.region;
                state.apiPayload = {
                    ...state.apiPayload,
                    region: action?.payload?.region
                };
            }

            if (action?.payload?.technology && action?.payload?.technology?.length > 0) {
                state.technology.selected = action?.payload?.technology;
                state.technology.currentSelection = action?.payload?.technology;
            }

            if (action?.payload?.interconnector && action?.payload?.interconnector?.length > 0) {
                state.interconnector.selected = action?.payload?.interconnector;
                state.interconnector.currentSelection = action?.payload?.interconnector;
            }

            if (action?.payload?.singleRegions && action?.payload?.singleRegions?.length > 0) {
                state.singleRegions.selected = action?.payload?.singleRegions;
                state.singleRegions.currentSelection = action?.payload?.singleRegions;
            }

            if (
                action?.payload?.singleTechnology &&
                action?.payload?.singleTechnology?.length > 0
            ) {
                state.singleTechnology.selected = action?.payload?.singleTechnology;
                state.singleTechnology.currentSelection = action?.payload?.singleTechnology;
            }

            if (action?.payload?.view && action?.payload?.view?.length > 0) {
                state.capabilitiesRadioToggleSelect.currentSelection = action?.payload?.view;
            }
        },
        setErrors: (state, action: PayloadAction<IErrors>) => {
            state.errors[action?.payload?.name] = action?.payload?.value;
        },
        setSingleRegions: (state, action: PayloadAction<IRegionFilter>) => {
            const { data } = action?.payload;
            const finalData = createFiltersObject(data);
            state.singleRegions.data = finalData?.updatedData;
            state.singleRegions.selected = [finalData?.selectedData[0]];
            state.singleRegions.currentSelection = [finalData?.selectedData[0]];
            state.loader.singleRegions = false;
        },
        setSelectedSingleRegions: (state, action: PayloadAction<IRegionFilter>) => {
            const { data, updatedSelectedRegions, isRegionAndTechnologyFilter } = action?.payload;
            if (updatedSelectedRegions) {
                state.singleRegions.selected = updatedSelectedRegions;
                if (isRegionAndTechnologyFilter) {
                    const finalTechData = createTechFilter(data, updatedSelectedRegions);
                    state.singleTechnology.data = finalTechData?.updatedData;
                    state.singleTechnology.selected = [finalTechData?.selectedData[0]];
                }
            }
        },
        setSingleTechnology: (state, action: PayloadAction<IRegionFilter>) => {
            const { data, isRegionAndTechnologyFilter, selectedRegions, technologyData } =
                action?.payload;
            if (isRegionAndTechnologyFilter && selectedRegions && selectedRegions?.length > 0) {
                const finalTechData = createTechFilter(data, selectedRegions, technologyData);
                state.singleTechnology.data = finalTechData?.updatedData;
                state.singleTechnology.selected = [finalTechData?.selectedData[0]];
                state.singleTechnology.currentSelection = [finalTechData?.selectedData[0]];
                state.loader.singleTechnology = false;
            }
        },
        setSelectedSingleTechnology: (state, action: PayloadAction<Array<string>>) => {
            state.singleTechnology.selected = action?.payload;
        },
        setSelectedRadioOption: (state, action: PayloadAction<string>) => {
            state.capabilitiesRadioToggleSelect.currentSelection = action?.payload;
            if (action?.payload === CAPABILITIES_TOGGLE_VIEW.installedCapacityAndGenerationMix.id) {
                state.apiPayload = {
                    scenario: state.scenario.currentSelection,
                    ...{ [FilterEnum.REGIONS]: state.region.currentSelection }
                };
            } else {
                state.apiPayload = {
                    multiscenario: state.multiscenario.currentSelection
                };
            }
        },
        setSelectedProject: (state, action: PayloadAction<IFilterGeneric | null>) => {
            state.project.selected = action?.payload;
        },
        setAutoCompleteDDFlag: (state, action: PayloadAction<boolean>) => {
            state.filterDDState.project = action?.payload;
        },
        setInchartFilter: (state, action: PayloadAction<IInchartFilterPayload>) => {
            const { name, data, selectedId } = action?.payload;
            const item = { name, data, selectedId };
            state.inChartFilter = [...state.inChartFilter, item];
        },
        setSelectedInchartFilter: (
            state,
            action: PayloadAction<Omit<IInchartFilterPayload, 'data'>>
        ) => {
            const { name, selectedId } = action?.payload;
            state.inChartFilter.forEach((obj) => {
                if (obj.name === name) {
                    obj.selectedId = selectedId;
                }
            });
        },

        setScenarioIdfromQS: (state, action: PayloadAction<string>) => {
            state.scenarioIdfromQS = action.payload;
        },
        setYearAPISuccess: (state, action: PayloadAction<boolean>) => {
            state.yearAPIsuccess = action.payload;
        }
    }
});

export const {
    setScenario,
    setSelectedScenario,
    setTechnology,
    setTechnologyOnSelectAll,
    setSelectedTechnology,
    setRegions,
    setSelectedRegions,
    setRegionsOnSelectAll,
    setFilterDDState,
    setCurrentFilters,
    resetFilters,
    setInterConnector,
    setSelectedInterConnector,
    setInterConnectorOnSelectAll,
    applyFilters,
    setPreSelectedFilters,
    setErrors,
    setApiPayloadKeys,
    setSingleRegions,
    setSingleTechnology,
    setSelectedSingleTechnology,
    setSelectedSingleRegions,
    setSelectedRadioOption,
    setSelectedProject,
    setAutoCompleteDDFlag,
    setMultiScenario,
    setMultiScenarioOnParentSelect,
    setMultiScenarioOnChildSelect,
    setMultiScenarioToastClose,
    resetView,
    setInchartFilter,
    setSelectedInchartFilter,
    setScenarioIdfromQS,
    setYearAPISuccess
} = filterReducer.actions;

export default filterReducer.reducer;
