import { MotifModal } from '../../../assets/lib/motif';
import styled from 'styled-components';
import { IModalProps } from './Modal';

const StyledModel = styled(({ children, className = '', ...props }: IModalProps) => (
    <MotifModal className={className} {...props}>
        {children}
    </MotifModal>
))`
    &.motif-modal-size-xxl {
        width: 1200px;
    }
    &.custom-project-modal {
        max-width: 1200px;
    }

    &.motif-modal-size-xxxl {
        width: 1500px;
        max-width: 1500px;
    }

    .emass-modal__footer {
        flex-direction: row;
        grid-gap: 20px;

        button + button {
            margin-top: 0;
        }
    }

    .custom-error-message {
        padding-left: 20px;
        padding-top: 10px;
    }
`;

export default StyledModel;
