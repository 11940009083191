import { FC } from 'react';
import { MotifCheckbox } from '../../../assets/lib/motif';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
    id: string;
    checked?: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    name?: string;
    value?: string;
    className?: string;
    children?: React.ReactNode;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: FC<CheckboxProps> = ({
    id,
    checked = false,
    indeterminate,
    disabled,
    name,
    value = '',
    className = '',
    children,
    onChange,
    ...other
}) => (
    <MotifCheckbox
        {...other}
        id={id}
        name={name}
        checked={checked}
        indeterminate={indeterminate}
        disabled={disabled}
        value={value}
        className={className}
        onChange={onChange}>
        {children}
    </MotifCheckbox>
);

export default Checkbox;
