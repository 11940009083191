import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyles = createGlobalStyle`

:root, .motif-theme-light, body[motif-theme=light],[motif-theme=dark], .motif-theme-dark {
    --body--bg-color: ${theme?.bodyBg};
    --brand-color: ${theme?.brandColor};
    --icon-color: ${theme?.iconColor};
    --progress-loader--bg-color: ${theme?.progressLoaderBgColor};

    --header--bg-color: ${theme?.headerBgColor};
    --header-icon-color: ${theme?.iconColor};
    --header-logo--bg-color: ${theme?.headerBgColor};
    --header-title--color: ${theme?.headerTitleColor};

    --avatar-bg-color-list: ${theme?.avatarBgColorList};
    --avatar-initials--bg-color: ${theme?.avatarBgColor};
    --avatar-initials-icon--color: ${theme?.avatarInitialsIconColor};

    --vertical-navigation--bg-color: ${theme?.sideBarBgColor};
    --vertical-navigation-menu-item-icon-active-icon-color: ${theme?.sideBarActiveColor};
    --vertical-navigation-menu-item-active--border-color: ${theme?.sideBarActiveBorderColor};
    --vertical-navigation-menu-item-active--border-width: ${theme?.sideBarActiveBorderWidth};
    --vertical-navigation-menu-item-active--border-style: ${theme?.sideBarActiveBorderStyle};
    --vertical-navigation-menu-item-active--bg-color: ${theme?.sideBarActiveBgColor};
    --vertical-navigation--border-width: ${theme?.sideBarBorderWidth};
    --vertical-navigation-submenu-item-link-active--color: ${theme?.iconColor};

    --table-odd-row--bg-color: ${theme?.tableOddRowBgColor};
    --table-even-row--bg-color: ${theme?.tableEvenRowBgColor};

    --tab-navigation-horizontal-button--border-width: 0;
    --tab-navigation--color: ${theme?.tabNavigationColor};
    --tab-navigation-hover--color: ${theme?.tabNavigationColor};
    --tab-navigation-active--color: ${theme?.tabNavigationActiveColor};
    --tab-navigation-hover--border-style: ${theme?.tabNavigationBorderStyle};
    --tab-navigation-horizontal-button-hover--border-style: ${theme?.tabNavigationBorderStyle};
    --tab-navigation-hover--bg-color: ${theme?.tabNavigationActiveBgColor};
    --tab-navigation-active--bg-color: ${theme?.tabNavigationActiveBgColor};
    --tab-navigation-hover--border-width: ${theme?.tabNavigationBorderWidth};
    --tab-navigation-hover--border-radius: ${theme?.tabNavigationBorderRadius};
    --tab-navigation-focus--bg-color: ${theme?.tabNavigationActiveBgColor};
    --tab-navigation-focus--color: ${theme?.tabNavigationActiveColor};
    --tab-navigation-focus--outline-color: ${theme?.tabNavigationOutline};
    --tab-navigation-focus--border-radius: ${theme?.tabNavigationBorderRadius};

    --btn--font-weight: var(--weight-bold);
    --btn-primary--bg-color: var(--brand-color);
    --btn-primary--border-color: var(--brand-color);
    --btn-primary-focus--bg-color: var(--brand-color);
    --btn-primary-focus--border-color: var(--brand-color);
    --btn-primary-hover--bg-color: #eaeaf2;
    --btn-primary-hover--border-color: #eaeaf2;
    --btn-primary-active--bg-color: var(--brand-color);
    --btn-primary-active--border-color: var(--brand-color);
    --btn-primary-disabled--bg-color: #282836;
    --btn-primary-disabled--border-color: #282836;
    --btn-primary-disabled--color: #aaaab1;
    --btn-primary-disabled-icon-color: #aaaab1;
    --btn-primary-disabled-progress--bg-color: #aaaab1;

    --modal-footer--border-color: ${theme?.modalFooterBorderColor};

    --ag-background-color: ${theme?.bodyBg};
    --toast-info--border-color: #e6e6e9;
    --toast-info--bg-color: #2e2e38;
    --toast-info--color: #f9f9fa;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

*:focus:not(.focus-visible) {
    outline: none;
}

*:focus {
    outline: none !important;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 4px;
    height : 8px;
    background-color: ${theme?.scrollBarBgColor};
}

::-webkit-scrollbar-track {
    background:  ${theme?.scrollBarTrackBgColor} !important;
}

::-webkit-scrollbar-thumb {
    background:  ${theme?.scrollBarThumbBgColor} !important;
}

body {
  background-color: var(--body--bg-color);
  overflow-x: hidden;
}

a {
    border: 0 !important;
    box-shadow: none !important;
}

iframe {
    display: none;   
}

.hide-for-accessibility {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.highcharts-background {
  fill: transparent !important;
}

.highcharts-tooltip-custom {
  color: #ffffff;
  font-size: 14px;
  height: fit-content;
  white-space: nowrap;
  padding: 6px 7px;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  .tooltip-color {
    width :8px; 
    height :8px; 
    border-radius : 50%;
    flex: 0 0 8px;
  }
  .tooltip-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5rem;
  }
  .tooltip-time {
    text-overflow: unset;
  }
  .tooltip-name-ipc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none !important ;
  }
  .tooltip-value {
    color: #ffffff;
    font-family: EYInterstate;
    font-size: 12px;
    align-items: center;
    text-align: center;
  }
}

.show-loader-with-data {
  background-color: red;
  height: 800px;
  z-index: 8;
  position: absolute;
  width: 100%;
  opacity: 0.6;
}

.disabled-cls {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

.highcharts-credits{
    display: none;
}

.toolTip {
    display: flex;
    flex-direction: column;
    background-color: #FFE600 !important;
    font-size:20px;
}

.list-class {
  display: flex;
  flex-direction: column;
}

.slider-div {
  margin-bottom:40px;

  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: #FFF;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    rotate : -180 def;
}


  .rc-slider-with-marks {
     z-index:9;
      width: 80%;
      margin: 0 auto;
      margin-bottom:40px;

      margin-left: 10px;
      margin-bottom:30px;
      .rc-slider-rail {
          height: 2px;
          background-color: yellow;
      }
      .rc-slider-track {
          background-color: yellow;
          height: 0px;
      }
      .rc-slider-dot {
          border-radius: unset;
          width: 0px;
          bottom: -3px;
          height: 12px;
          border-color: yellow;
      }
      .rc-slider-handle:active,
      .rc-slider-handle:focus {
          box-shadow: none;
      }
      .rc-slider-handle {
          border: 0;
          background-color: transparent;
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='18' viewBox='0 0 8 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='7.41804' height='18' transform='matrix(-1 0 0 1 7.41805 0)' fill='%23C4C4CD'/%3E%3Crect x='2.78177' y='3' width='1.85451' height='12' fill='%231A1A24'/%3E%3C/svg%3E%0A");
          width: 8px;
          height: 21px;
          background-repeat: no-repeat;
          margin-top: -7px;
          border-radius: 0;
      }
  }
}

.slider-packed-bubble {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.widget-options-menu {
  z-index: 1
}
`;

export default GlobalStyles;
