import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    PRIVACY_NOTICE,
    I_AGREE_PRIVACY_NOTICE_TEXT,
    TNU_TITLE,
    I_AGREE_TNU_TEXT
} from './Policies.label.const';
import { IPolicyTypeObject, showPolicyModal } from 'core/store/userProfile.reducer';
import AppHeader from 'components/custom/AppHeader';
import AppFooter from 'components/custom/AppFooter';
import { acceptPrivacyPolicy } from 'core/http';
import { RootState, useAppSelector } from 'core/store';
import { Icon, Loader } from 'components/base';
import PolicyContent from 'components/custom/AppFooter/PolicyContent/PolicyContent';
import { lockIcon, tncIcon } from 'assets/lib/common/icons';
import { ProgressIndicator, ProgressIndicatorStep } from 'components/base/ProgressIndicator';
import PolicyModal from './PolicyModal';
import { StylePrivacy } from './Policies.style';
import { POLICY_TYPE } from 'core/base/enum';
import WindowService from 'common/utils/window.utils';

const Policies: React.FC = () => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState<POLICY_TYPE>(POLICY_TYPE.PRIVACY_NOTICE);
    const [loading, setLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const policyTypeList = useAppSelector((state: RootState) => state?.userProfile?.policyTypeList);

    const handleActionButton = async (type: POLICY_TYPE) => {
        setLoading(true);
        if (type === POLICY_TYPE.PRIVACY_NOTICE) {
            setActiveTab(POLICY_TYPE.TERMS_OF_USE);
        } else if (type === POLICY_TYPE.TERMS_OF_USE) {
            const hasAccepted = await acceptPrivacyPolicy();
            if (hasAccepted.hasAccepted) {
                dispatch(showPolicyModal({ policyTypeList: [], showPolicy: false }));
                WindowService.reloadPage();
            } else {
                setIsError(true);
            }
        }
        setLoading(false);
    };

    const filterContent = (type: POLICY_TYPE) => {
        const response: IPolicyTypeObject = policyTypeList.filter(
            (policy) => policy.policyType === type
        )[0];
        return response.content;
    };

    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <StylePrivacy>
                <div className="main-content">
                    <AppHeader />
                    <div className="wrapper-progressBar">
                        <div className="policy-modal">
                            <ProgressIndicator variant="row" className="custom-progress-indicator">
                                <ProgressIndicatorStep
                                    heading="Privacy Notice"
                                    onClick={() => {}}
                                    status={
                                        activeTab === POLICY_TYPE.PRIVACY_NOTICE
                                            ? 'active'
                                            : 'default'
                                    }
                                    icons={{
                                        complete: <Icon src={lockIcon} />
                                    }}
                                />
                                <ProgressIndicatorStep
                                    heading="Terms Of Use"
                                    onClick={() => {}}
                                    status={
                                        activeTab === POLICY_TYPE.TERMS_OF_USE
                                            ? 'active'
                                            : 'default'
                                    }
                                    icons={{
                                        complete: <Icon src={tncIcon} />
                                    }}
                                />
                            </ProgressIndicator>

                            {activeTab === POLICY_TYPE.PRIVACY_NOTICE && (
                                <PolicyModal
                                    type={POLICY_TYPE.PRIVACY_NOTICE}
                                    title={PRIVACY_NOTICE}
                                    agreeText={I_AGREE_PRIVACY_NOTICE_TEXT}
                                    isError={isError}
                                    handleActionButton={handleActionButton}>
                                    <PolicyContent
                                        content={filterContent(POLICY_TYPE.PRIVACY_NOTICE)}
                                    />
                                </PolicyModal>
                            )}
                            {activeTab === POLICY_TYPE.TERMS_OF_USE && (
                                <PolicyModal
                                    type={POLICY_TYPE.TERMS_OF_USE}
                                    title={TNU_TITLE}
                                    agreeText={I_AGREE_TNU_TEXT}
                                    isError={isError}
                                    handleActionButton={handleActionButton}>
                                    <PolicyContent
                                        content={filterContent(POLICY_TYPE.TERMS_OF_USE)}
                                    />
                                </PolicyModal>
                            )}
                        </div>
                    </div>
                </div>
            </StylePrivacy>
            <AppFooter />
        </>
    );
};

export default React.memo(Policies);
