import { actionIcDashboard24px } from '../../../assets/lib/motif/icons';
import { FilterEnum } from '../enum';
import { ROUTES } from './routes';
export * from './api';
export * from './routes';
export * from './whatIfScenario';
export * from './table';

type KeyType = string | number;

export type IGenericObject<T = unknown> = { [key in KeyType]: T };

export const HEADER = {
    APP_HEADER_NAME: 'Energy Market Model',
    EY_LOGO_HOME: 'EY Logo - Home',
    CONTACTUS: {
        EMAIL: 'EMMsupport@au.ey.com',
        SUBJECT: ''
    },
    HELP: {
        LINK: 'https://www.ey.com'
    },
    LOGOUT: {
        CONFIRMATION_TEXT: 'Are you sure want to logout?'
    }
};

export const PROFILE_CARD_MENU = {
    help: 'Help',
    contactUs: 'Contact us',
    logOut: 'Logout',
    modalHeaderText: 'Logout',
    modalActionButtonText: 'Logout',
    modalCancelButtonText: 'Close'
};

export enum SIDEBAR {
    dashboard = 'Dashboard'
}

export const sideBarMenuList = [
    {
        id: 1,
        item: SIDEBAR.dashboard,
        icon: actionIcDashboard24px,
        keyName: SIDEBAR.dashboard,
        path: ROUTES.INVESTOR_MODULE,
        role: '',
        relativeRoutes: ''
    }
];

export const FOOTER = {
    privacyNotice: 'Privacy Notice',
    engagement: 'Engagement Management',
    termsOfUse: 'Terms Of Use'
};

export const FOOTER_LEAGAL_SECTION = `EY refers to the global organization, and may refer to one or more, of the
member firms of Ernst & Young Global Limited, each of which is a separate legal
entity. Ernst & Young Global Limited, a UK company limited by guarantee, does
not provide services to clients`;

export const SCENARIO_LIMIT_MESSAGE = 'Maximum 10 scenarios can be selected at a time.';

export const ENGAGEMENT_MANAGRMENT_URL = 'https://go.ey.com/45b78io';

export const GLOBAL_ERROR_BOUNDARY_MESSAGE = {
    title: 'Something Went Wrong.',
    description: 'Please try again or report an issue to support'
};

export const FITLER_BUTTONS = {
    clearAll: 'Clear All',
    close: 'Close',
    apply: 'Apply',
    reset: 'Reset'
};

export const MODAL_BUTTONS = {
    close: 'Close',
    apply: 'Apply',
    reset: 'Reset'
};
export const DEFAULT_LEGEND_COLOR = '#CCC';
export const colorPalette = [
    '#7f5cc7',
    '#4575b0',
    '#FF00F5',
    '#CBB781',
    '#8CE8AD',
    '#188CE5',
    '#35A4E8',
    '#168736',
    '#6DCBBC',
    '#FFA600',
    '#95B809',
    '#9F0131',
    '#D37EAF',
    '#F95D54',
    '#C62B97',
    '#7f5cc7',
    '#4575b0',
    '#FF00F5',
    '#CBB781',
    '#8CE8AD',
    '#188CE5',
    '#35A4E8',
    '#168736',
    '#6DCBBC',
    '#FFA600',
    '#95B809',
    '#9F0131',
    '#D37EAF',
    '#F95D54',
    '#C62B97',
    '#7f5cc7',
    '#4575b0',
    '#FF00F5',
    '#CBB781',
    '#8CE8AD',
    '#188CE5',
    '#35A4E8',
    '#168736',
    '#6DCBBC',
    '#FFA600',
    '#95B809',
    '#9F0131',
    '#D37EAF',
    '#F95D54',
    '#C62B97'
];

export const colorPalettePool: IGenericObject<string> = {
    // Yellow
    'yellow-1': '#FEF7C3',
    'yellow-3': '#FDE272',
    'yellow-4': '#FAC515',
    'yellow-5': '#EAAA08',
    'yellow-6': '#CA8504',
    'yellow-2': '#FEEE95',
    'yellow-7': '#A15C07',
    // Blues
    'blue-2': '#4EBEEB',
    'blue-3': '#35A4E8',
    'blue-4': '#188CE5',
    'blue-5': '#1777CF',
    'blue-6': '#4575b0',
    'blue-1': '#87D3F2',
    'blue-7': '#316bc9',
    // Greens
    'green-2': '#57E188',
    'green-3': '#34C768',
    'green-4': '#2DB757',
    'green-5': '#189D3E',
    'green-6': '#168736',
    'green-7': '#3e7e50',
    'green-1': '#8DE8AD',
    // Reds
    'red-2': '#FF736A',
    'red-3': '#F95D54',
    'red-4': '#FF4236',
    'red-5': '#E0362C',
    'red-6': '#c44b45',
    'red-1': '#FF9A91',
    'red-7': '#a65d59',
    // Purples
    'purple-2': '#7f5cc7',
    'purple-3': '#8e72ca',
    'purple-4': '#8869bf',
    'purple-5': '#7861cc',
    'purple-6': '#6664b9',
    'purple-1': '#9C82D4',
    'purple-7': '#4a4ea1',
    // Teals
    'teal-2': '#60E6E1',
    'teal-3': '#42C9C2',
    'teal-4': '#27ACAA',
    'teal-5': '#109090',
    'teal-6': '#0f7b7b',
    'teal-1': '#93F0E6',
    'teal-7': '#2b7878',
    // Marrons
    'marron-2': '#b54599',
    'marron-3': '#ff00bf',
    'marron-4': '#a05488',
    'marron-5': '#a97097',
    'marron-6': '#87647c',
    'marron-1': '#C981B2',
    'marron-7': '#5a4353'
};
export const scatterChartColorPalette = ['rgba(255,218,3,1)', 'rgba(217,72,1,1)'];

export const Q_QUERY_KEY = 'q';
export const MAIN = 'MAIN';
export const SUB_PAGE = 'SUB_PAGE';
export const SUB_PAGE_CHILD = 'SUB_PAGE_CHILD';
export const MAX_SCENARIO_SELECTED = 10;

export const FILTER_LABELS = {
    selectScenario: 'Select Scenario',
    scenario: 'Scenario',
    selectTechnology: 'All Technology',
    selectGenerator: 'Select Generator',
    selectInterconnector: 'All Interconnector',
    selectRegions: 'All Regions',
    SELECT_ALL: 'Select All',
    regions: 'Regions',
    singleRegion: 'Select Region',
    technology: 'Technology',
    singleTechnology: 'Select Technology',
    interconnector: 'Interconnector'
};

export const CURRENCY = {
    AUD: {
        code: 'AUD',
        symbol: '$'
    },
    USD: {
        code: 'USD',
        symbol: '$'
    },
    GBP: {
        code: 'GBP',
        symbol: '£'
    },
    EUR: {
        code: 'EUR',
        symbol: '€'
    },
    INR: {
        code: 'INR',
        symbol: '₹'
    }
};

export const FILTERS_PER_TAB = {
    GENERATORS_BY_TECHNOLOGY: [FilterEnum.SCENARIO, FilterEnum.TECHNOLOGY, FilterEnum.REGIONS],
    PRICES: [FilterEnum.SCENARIO],
    INTERCONNECTOR_FLOW: [FilterEnum.SCENARIO, FilterEnum.INTERCONNECTOR],
    NETWORK: [FilterEnum.SCENARIO],
    TARGET_FULFILMENT: [FilterEnum.SCENARIO, FilterEnum.REGIONS],
    PROJECT_PERFORMANCE: [
        FilterEnum.SCENARIO,
        FilterEnum.SINGLE_REGIONS,
        FilterEnum.SINGLE_TECHNOLOGY,
        FilterEnum.PROJECT
    ],
    COMPARISON_CAPABILITIES: [
        FilterEnum.SCENARIO,
        FilterEnum.REGIONS,
        FilterEnum.COMPARISON_CAPABILITIES
    ],
    COMPARISON_CAPABILITIES_VIEW_TWO: [
        FilterEnum.MULTI_SCENARIO,
        FilterEnum.COMPARISON_CAPABILITIES
    ],
    PROJECT_OUTCOMES: [
        FilterEnum.MULTI_SCENARIO,
        FilterEnum.SINGLE_REGIONS,
        FilterEnum.SINGLE_TECHNOLOGY,
        FilterEnum.PROJECT
    ]
};

export const PAYLOAD_PER_TAB = {
    GENERATORS_BY_TECHNOLOGY: [FilterEnum.SCENARIO, FilterEnum.REGIONS],
    PRICES: [FilterEnum.SCENARIO],
    INTERCONNECTOR_FLOW: [FilterEnum.SCENARIO],
    NETWORK: [FilterEnum.SCENARIO],
    TARGET_FULFILMENT: [FilterEnum.SCENARIO, FilterEnum.REGIONS],
    PROJECT_PERFORMANCE: [
        FilterEnum.SCENARIO,
        FilterEnum.SINGLE_REGIONS,
        FilterEnum.SINGLE_TECHNOLOGY,
        FilterEnum.PROJECT
    ],
    MARKET_OUTCOMES: [FilterEnum.SCENARIO, FilterEnum.REGIONS],
    PROJECT_OUTCOMES: [
        FilterEnum.MULTI_SCENARIO,
        FilterEnum.SINGLE_REGIONS,
        FilterEnum.SINGLE_TECHNOLOGY,
        FilterEnum.PROJECT
    ]
};

export const FILTERS_TITLE_LENGTH = 25;

export const INTRO_PAGE = {
    buttonLabel: 'Begin Your EY Energy Market Model Journey'
};

export const PROJECT_FILTER_DATA_LIMIT = 20;
export const PROJECT_FILTER_CHAR_LIMIT = 3;
export const CAPABILITIES_TOGGLE_VIEW = {
    installedCapacityAndGenerationMix: {
        name: 'Installed Capacity & Generation Mix',
        id: '1'
    },
    InterconnectorFlowsAndPrices: { name: 'Interconnector Flows & Prices', id: '2' }
};

export const TIME_WEIGHTED_AND_DISPATCH_WEIGHTED_PRICE_LEGEND = {
    TWPP: 'TWPP',
    DWPP: 'DWPP',
    TWP: 'TWP',
    DWP: 'DWP'
};

export const SEARCH_PLACEHOLDER = 'Search';
export const SEARCH_BUTTON_TITLE = 'Search';

//this motif colors are provided by business to be used in chart

export const motifColors = [
    '#5E36BC',
    '#ffe600',
    '#85B9FD',
    '#73FA90',
    '#FCD9BC',
    '#FF735E',
    '#E180C0',
    '#E7FCE8',
    '#60F6F3',
    '#17706F',
    '#C2ABEA',
    '#f2d9e8',
    '#197335',
    '#83000B',
    '#623300',
    '#064372',
    '#ff9831',
    '#D07200',
    '#B26100',
    '#D2E1FA'
];
