import { MotifModalBody } from "assets/lib/motif";
import { FC } from "react";

interface IModalBodyProps {
    bodyExtraProps?: Record<string, unknown>;
    bodyClassName?: string;
    children: React.ReactNode;
}

const ModelBody: FC<IModalBodyProps>  = ({
    bodyExtraProps = {},
    bodyClassName = '',
    children,
}) => {

    return (
        <MotifModalBody {...bodyExtraProps} className={`emass-modal__body ${bodyClassName}`}>
            {children}
        </MotifModalBody>
    )
}

export default ModelBody;