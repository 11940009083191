import { UnknownAction, EnhancedStore, configureStore } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './rootReducer';
import { middleware as apiMiddleware } from '../api/api.base';
import { isDevEnv } from 'common/utils/env';
import { appSettingInitialState } from './appSetting.reducer';
import { filterInitialState } from './filter.reducer';
import { whatIfScenariosInitialState } from './whatIfScenarios.reducer';
import { userProfileInitialState } from './userProfile.reducer';

export type InitialStoreState = Omit<RootState, 'api'> & Partial<Pick<RootState, 'api'>>;

export const initialState: InitialStoreState = {
    appSetting: appSettingInitialState,
    filter: filterInitialState,
    whatIfScenarios: whatIfScenariosInitialState,
    userProfile: userProfileInitialState
};

export const getStore = (
    preloadedState = initialState
): EnhancedStore<RootState, UnknownAction> => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleWares) =>
            getDefaultMiddleWares({ serializableCheck: false }).concat(apiMiddleware),
        devTools: isDevEnv
    });
};

export const store = getStore();

export type AppDispatch = typeof store.dispatch;