import { FC, ReactNode } from 'react';
import { MotifHeaderLogo } from '../../../../assets/lib/motif';

type HeaderLogoProps = {
    children: ReactNode;
};

const HeaderLogo: FC<HeaderLogoProps> = ({ children }) => {
    return <MotifHeaderLogo>{children}</MotifHeaderLogo>;
};

export default HeaderLogo;
