import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'core/configs/authConfig';
import { INTRO_PAGE } from 'core/base/const';
import { Button, Loader } from 'components/base';
import { StyledIntro } from './AppIntroduction.style';
import { FallBack } from '../ErrorBoundary';
import Image from 'components/base/Image';
import EY_logo from 'assets/lib/common/images/EY_Logo_Beam_Tag_Stacked_White_C_CMYK_EN.svg';

const AppIntroduction: React.FC = () => {
    const { instance } = useMsal();

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleLoginRedirect = () => {
        setIsLoading(true);
        instance
            .loginRedirect({
                ...loginRequest,
                prompt: 'create'
            })
            .catch((error) => {
                setError(error);
            });
    };
    if (isLoading) {
        return <Loader />;
    } else if (error) {
        return <FallBack reloadDocument={true} />;
    }
    return (
        <StyledIntro>
            <div className="handler-btn">
                <Image imageTestId="logo-img-test-id" className="log-img" src={EY_logo} />
                <Button onClick={handleLoginRedirect} size="large">
                    {INTRO_PAGE.buttonLabel}
                </Button>
            </div>
        </StyledIntro>
    );
};

export default AppIntroduction;
