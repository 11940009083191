import React, { useState, useContext } from 'react';
import { SIDEBAR, sideBarMenuList } from '../../../core/base/const';
import { Sidebar, SidebarContent, SidebarMenu, SidebarMenuItem } from '../../base/Sidebar';
import Icon from '../../base/Icon';
import { StyleSidbar, StyleSidbarWrapper } from './AppSidebar.style';
import { SidebarContext } from '../../../common/context';

type sidebarRoute = {
    id?: number;
    item?: SIDEBAR;
    icon?: string;
    keyName: SIDEBAR;
    path?: string;
    role?: string;
    relativeRoutes?: string;
};

const AppSidebar: React.FC = () => {
    const [selectedMenu, setSelectedMenu] = useState<string>(SIDEBAR.dashboard);
    const { isExpanded } = useContext(SidebarContext);
    const handleRoute = (item: sidebarRoute): void => {
        setSelectedMenu(item.keyName);
    };

    return (
        <StyleSidbarWrapper isExpanded={isExpanded}>
            <StyleSidbar>
                <div className="sidebar-wrapper">
                    <Sidebar className="example-root-class" collapse={!isExpanded}>
                        <SidebarContent>
                            <SidebarMenu>
                                {sideBarMenuList?.map((route) => {
                                    return (
                                        <SidebarMenuItem
                                            icon={<Icon src={route.icon} />}
                                            key={route.id}
                                            id={route.id}
                                            title={route.keyName}
                                            onClick={() => handleRoute(route)}
                                            selected={selectedMenu === route.keyName}>
                                            {!isExpanded ? '' : route.item}
                                        </SidebarMenuItem>
                                    );
                                })}
                            </SidebarMenu>
                        </SidebarContent>
                    </Sidebar>
                </div>
            </StyleSidbar>
        </StyleSidbarWrapper>
    );
};

export default React.memo(AppSidebar);
