import * as DOMPurify from 'dompurify';

const allowedTags = [
    '#text',
    'table',
    'tbody',
    'tr',
    'th',
    'td',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'div',
    'p',
    'br',
    'span',
    'ul',
    'ol',
    'li',
    're',
    'blockquote',
    'strong',
    'em',
    'b',
    'i',
    'u',
    'sub',
    'sup',
    'strike',
    'a',
    'nobr',
    'hr',
    'tt',
    'q',
    'cite',
    'abbr',
    'acronym',
    'center'
];
const allowedAttr = [
    'align',
    'alt',
    'background',
    'bgcolor',
    'border',
    'cellpadding',
    'cellspacing',
    'class',
    'color',
    'cols',
    'colspan',
    'headers',
    'height',
    'href',
    'nowrap',
    'rows',
    'rowspan',
    'span',
    'style',
    'tabindex',
    'target',
    'title',
    'valign',
    'width'
];

const sanitizeHtml = (htmlInput: string): string => {
    const output = DOMPurify.sanitize(htmlInput, {
        ALLOWED_TAGS: allowedTags,
        ALLOWED_ATTR: allowedAttr,
        KEEP_CONTENT: false
    });
    return output.replaceAll(/(&lt;).*?(&gt;)/gi, '');
};

export { sanitizeHtml };
