import React from 'react';
import { MotifDatePicker } from '../../../assets/lib/motif';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Label from '../Label';
import FormField from '../FormField';
import { StyledDatePicker } from './DatePickerStyled';
interface DatePickerProps {
    label?: string;
    formFieldClassName?: string;
    formLabelClassName?: string;
    autoFocus?: boolean;
    calendarAriaLabel?: string;
    calendarClassName?: string | string[];
    calendarIcon?: boolean | React.ReactNode;
    className?: string | string[];
    clearAriaLabel?: string;
    clearIcon?: React.ReactNode;
    showClearIcon?: boolean;
    closeCalendar?: boolean;
    dayAriaLabel?: string;
    dayPlaceholder?: string;
    disabled?: boolean;
    disableCalendar?: boolean;
    calendarType?: string;
    format?: string;
    isOpen?: boolean;
    locale?: string;
    maxDate?: Date;
    maxDetail?: string;
    minDetail?: string;
    minDate?: Date;
    monthAriaLabel?: string;
    monthPlaceholder?: string;
    name?: string;
    nativeInputAriaLabel?: string;
    onCalendarClose?: (evt?: React.FocusEvent) => void;
    onCalendarOpen?: (evt?: React.FocusEvent) => void;
    onChange?: (evt: Date) => void;
    openCalendarOnFocus?: boolean;
    portalContainer?: HTMLElement;
    required?: boolean;
    returnValue?: string;
    showLeadingZeros?: boolean;
    value?: Date | [Date] | null;
    yearAriaLabel?: string;
    yearPlaceholder?: string;
    errorMessage?: string;
    wrapperClassName?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
    label = 'Select Date',
    formFieldClassName = '',
    formLabelClassName = '',
    clearIcon = null,
    showClearIcon,
    errorMessage,
    dayPlaceholder = 'DD',
    format = 'dd-MM-yyyy',
    monthPlaceholder = 'MM',
    onCalendarClose = () => null,
    onCalendarOpen = () => null,
    onChange = () => null,
    value = null,
    yearPlaceholder = 'YYYY',
    disableCalendar = false,
    disabled = false,
    className,
    wrapperClassName = ''
}: DatePickerProps) => {
    const customeFormFieldClassName = `${
        value ? 'motif-form-field-focused' : ''
    } ${formFieldClassName} motif-form-field-date-input`;
    return (
        <StyledDatePicker className={wrapperClassName}>
            <FormField className={customeFormFieldClassName}>
                <Label className={formLabelClassName}>{label}</Label>
                <MotifDatePicker
                    className={className}
                    clearIcon={showClearIcon ? clearIcon : ''}
                    dayPlaceholder={dayPlaceholder}
                    format={format}
                    monthPlaceholder={monthPlaceholder}
                    onCalendarClose={onCalendarClose}
                    onCalendarOpen={onCalendarOpen}
                    onChange={onChange}
                    value={value}
                    disableCalendar={disableCalendar}
                    yearPlaceholder={yearPlaceholder}
                    disabled={disabled}
                    onClick={() => null}
                    onFocus={() => null}
                    onKeyDown={() => null}
                />
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </FormField>
        </StyledDatePicker>
    );
};

export default React.memo(DatePicker);
