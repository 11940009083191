import React, { useMemo } from 'react';
import { MotifSelect, MotifOption } from '../../../assets/lib/motif';

export type SelectOptionType = {
    name: string;
    id: string;
    disabled?: boolean;
    level?: number;
};

interface SelectProps {
    testId?: string;
    id: string;
    name: string;
    value: string | string[];
    options: Array<SelectOptionType>;
    required?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    filter?: boolean;
    visibleOptions?: number;
    className?: string;
    noResultsTemplate?: React.ReactNode;
    optionClassName?: string;
    onChange: (option: string) => void;
    onBlur?: (evt?: React.FocusEvent) => void;
    onFocus?: (evt?: React.FocusEvent) => void;
}

const Select: React.FC<SelectProps> = ({
    testId = '',
    disabled = false,
    id,
    multiple = false,
    filter = false,
    required = false,
    visibleOptions,
    value,
    className = '',
    name,
    options = [],
    optionClassName = '',
    onChange,
    onBlur,
    onFocus
}: SelectProps) => {
    const getSelectedItemClass = (itemId: string, level = '') => {
        let itemClassName = optionClassName;

        if (itemId === value) {
            itemClassName += ` motif-option-current `;
        }
        if (level) {
            itemClassName += ` child-option-${level} `;
        }
        return itemClassName;
    };

    const triggerButtonProps = useMemo(() => ({ type: 'button' }), []);

    const noOptionFound = () => <MotifOption disabled>No results found</MotifOption>;

    return (
        <>
            <MotifSelect
                data-test-id={testId}
                disabled={disabled}
                id={id}
                filter={filter}
                multiple={multiple}
                required={required}
                visibleOptions={visibleOptions}
                className={className}
                name={name}
                value={String(value)}
                noResultsTemplate={noOptionFound}
                triggerButtonProps={triggerButtonProps}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}>
                {options.map(({ id, name, disabled, level }) => {
                    return (
                        <MotifOption
                            className={`${getSelectedItemClass(id, level ? String(level) : '')}`}
                            key={`${id}${name}`}
                            value={String(id)}
                            disabled={disabled}>
                            {name}
                        </MotifOption>
                    );
                })}
            </MotifSelect>
        </>
    );
};

export default React.memo(Select);
