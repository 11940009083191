const PRIVACY_NOTICE = 'Privacy Notice';
const TNU_TITLE = 'Terms Of Use';

const I_AGREE_TO_SATEMENT = `I agree to the privacy statement`;

const BUTTONS = {
    CONTINUE: 'Continue',
    CONTINUE_TO_EY: 'Continue to Climate Economics Model Journey'
};
const I_AGREE_PRIVACY_NOTICE_TEXT = `By clicking on the "I ACCEPT/ACKNOWLEDGE" button you are confirming you have reviewed the Privacy Notice and consent to the collection, use, disclosure and handling of your personal information for the purposes of, and in accordance with, the activities and terms, set out in this Privacy Notice.`;
const I_AGREE_TNU_TEXT = `I agree to the Terms of Use.`;

export {
    PRIVACY_NOTICE,
    I_AGREE_TO_SATEMENT,
    BUTTONS,
    I_AGREE_PRIVACY_NOTICE_TEXT,
    TNU_TITLE,
    I_AGREE_TNU_TEXT
};
