import { FC } from 'react';
import Container from 'assets/styled/Container';

const PageNotFound: FC = () => {
    return (
        <Container paddingTop="60px" paddingBottom="60px" paddingLeft="60px" paddingRight="60px">
            <h1 className="motif-h1">Page Not Found</h1>
        </Container>
    );
};

export default PageNotFound;
