import { FC, PropsWithChildren } from 'react';
import { MotifFooter } from '@ey-xd/motif-react';

type FooterProps = {
    className?: string;
};

const Footer: FC<PropsWithChildren<FooterProps>> = ({ className = '', ...props }) => {
    return <MotifFooter className={className} {...props} />;
};

export default Footer;
