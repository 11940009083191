import styled from 'styled-components';

export const StyledDatePicker = styled.div`
    .motif-date-picker-wrapper {
        width: auto !important;

        .react-date-picker {
            &__calendar {
                width: 100% !important;

                &--open {
                    left: 0 !important;
                }
            }
        }

        .react-calendar {
            &__navigation {
                button:enabled:hover, 
                button:enabled:focus {
                    background-color: transparent !important;
                }
                &__arrow {
                    font-size: 1.9rem !important;
                    height: 2.9rem !important;
                    min-width: 1rem !important;

                    &:disabled {
                        background-color: transparent !important;
                        cursor: not-allowed;
                        opacity: 0.25;
                    }
                }
            }
        }

        .motif-calendar {
            // width: 17rem !important;
            position: relative;
            z-index: 99 !important;
        }
    }
`;
