import styled from 'styled-components';

export const StyledAppHeader = styled.div`
    .motif-header-profile-card {
        position: relative;
        top: 14px;
        right: 19px;
        .motif-avatar {
            cursor: pointer;
        }
    }
`;
