import { FC } from 'react';
import { Link } from 'react-router-dom';
import StyledFallback from './Fallback.style';
import { GLOBAL_ERROR_BOUNDARY_MESSAGE } from 'core/base/const';

type FallbackProps = {
    className?: string;
    message?: string;
    reloadDocument?: boolean;
    redirectToHome?: boolean;
};
const Fallback: FC<FallbackProps> = ({
    className = '',
    message = GLOBAL_ERROR_BOUNDARY_MESSAGE.description,
    reloadDocument = false,
    redirectToHome = true
}) => {
    return (
        <StyledFallback.Wrapper className={className}>
            <StyledFallback.Content className="fallback">
                <StyledFallback.Title className="fallback__title">
                    {GLOBAL_ERROR_BOUNDARY_MESSAGE.title}
                </StyledFallback.Title>
                <StyledFallback.Description>{message}</StyledFallback.Description>
                {redirectToHome && (
                    <Link to={'/'} reloadDocument={reloadDocument}>
                        Go Back To Home
                    </Link>
                )}
            </StyledFallback.Content>
        </StyledFallback.Wrapper>
    );
};

export default Fallback;
