import React, { FC, useEffect, useState } from 'react';
import { GLOBAL_ERROR_BOUNDARY_MESSAGE, HEADER, PROFILE_CARD_MENU } from '../../../core/base/const';
import { logoutIcon } from '../../../assets/lib/common/icons';
import { actionIcHelp24px, communicationIcEmail24px } from '../../../assets/lib/motif/icons';
import Dropdown, { DropdownItem } from '../../base/Dropdown';
import Modal, { ModalSize } from '../../base/Modal';
import Button from '../../base/Button';
import StyledAppHeaderProfileCard from './AppHeaderProfileCard.style';
import { RootState, useAppDispatch, useAppSelector } from 'core/store';
import { setUserLogout } from 'core/store/userProfile.reducer';
import { ErrorMessage } from 'components/base';

interface IDropdownItem {
    name: string;
    icon: string;
}

const AppHeaderProfileCard: FC = () => {
    const dispatch = useAppDispatch();
    const [showDropdown, setDropdown] = useState<boolean>(false);
    const [showModal, setModal] = useState<boolean>(false);
    const [confirmLogout, setConfirmLogout] = useState<boolean>(false);
    const [children, setChildren] = useState<IDropdownItem[]>([
        {
            name: PROFILE_CARD_MENU.contactUs,
            icon: communicationIcEmail24px
        },
        {
            name: PROFILE_CARD_MENU.logOut,
            icon: logoutIcon
        }
    ]);
    const logoutErrorState = useAppSelector((state: RootState) => state?.userProfile?.logoutError);
    const {
        displayName: userName,
        email: userEmail,
        profileImage,
        isExternal
    } = useAppSelector((state: RootState) => state?.userProfile?.userData);

    const triggerId = 'dropdown-trigger';

    useEffect(() => {
        if (!isExternal) {
            const updateChildren = [...children];
            updateChildren.unshift({ name: PROFILE_CARD_MENU.help, icon: actionIcHelp24px });
            setChildren(updateChildren);
        }
    }, []);

    useEffect(() => {
        if (confirmLogout) {
            dispatch(setUserLogout(true));
            if (logoutErrorState) {
                setModal(false);
            }
        }
    }, [confirmLogout]);

    const handleMenu = (): void => {
        setDropdown(!showDropdown);
    };

    const handleClickOutside = (): void => {
        setDropdown(false);
    };

    const handleHelp = (): void => {
        window.open(HEADER.HELP.LINK, '_blank');
    };

    const handleContactUs = (): boolean => {
        window.open(
            `mailto:${HEADER.CONTACTUS.EMAIL}?subject=${HEADER.CONTACTUS.SUBJECT}`,
            '_blank'
        );
        return false;
    };

    const handleLogout = (): void => {
        setModal(true);
    };

    const handleClickDropdownItem = (child = 'username'): void => {
        switch (child) {
            case PROFILE_CARD_MENU.help:
                handleHelp();
                break;
            case PROFILE_CARD_MENU.contactUs:
                handleContactUs();
                break;
            case PROFILE_CARD_MENU.logOut:
                handleLogout();
                break;
            default:
                break;
        }
    };
    const ModalBody = (): JSX.Element => {
        return (
            <>
                <StyledAppHeaderProfileCard.ModalBody>
                    <StyledAppHeaderProfileCard.ModalBodyIcon src={logoutIcon} />
                    {HEADER.LOGOUT.CONFIRMATION_TEXT}
                </StyledAppHeaderProfileCard.ModalBody>
                {logoutErrorState && (
                    <ErrorMessage>
                        {`${GLOBAL_ERROR_BOUNDARY_MESSAGE.title} ${GLOBAL_ERROR_BOUNDARY_MESSAGE.description}`}
                    </ErrorMessage>
                )}
            </>
        );
    };

    const ModalFooter: FC = () => {
        return (
            <>
                <Button type="button" onClick={() => setConfirmLogout(true)}>
                    {PROFILE_CARD_MENU.modalActionButtonText}
                </Button>
                <Button variant="secondary" type="button" onClick={() => setModal(false)}>
                    {PROFILE_CARD_MENU.modalCancelButtonText}
                </Button>
            </>
        );
    };

    const DropdownChildrens: FC = () => {
        return (
            <>
                {children.length > 0 &&
                    children.map((child, i) => (
                        <StyledAppHeaderProfileCard.Item
                            onClick={() => handleClickDropdownItem(child.name)}
                            key={i}>
                            <StyledAppHeaderProfileCard.Icon src={child.icon} />
                            {child.name}
                        </StyledAppHeaderProfileCard.Item>
                    ))}
            </>
        );
    };

    return (
        <>
            <Dropdown
                open={showDropdown}
                aria-labelledby={triggerId}
                handleClickOutside={handleClickOutside}
                trigger={
                    <StyledAppHeaderProfileCard.Avatar
                        userName={userName}
                        description={userEmail}
                        src={profileImage}
                        onClick={handleMenu}
                    />
                }>
                <DropdownItem onClick={() => handleClickDropdownItem()} key={'username'}>
                    <StyledAppHeaderProfileCard.Avatar
                        userName={userName}
                        description={userEmail}
                    />
                    {userName}
                </DropdownItem>
                <DropdownChildrens />
            </Dropdown>
            {showModal && (
                <Modal
                    show={showModal}
                    size={ModalSize.SMALL}
                    headerContent={PROFILE_CARD_MENU.modalHeaderText}
                    onClose={() => setModal(false)}
                    footerContent={<ModalFooter />}>
                    <ModalBody />
                </Modal>
            )}
        </>
    );
};

export default React.memo(AppHeaderProfileCard);
