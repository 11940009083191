export * from './http';
export * from './chart';

export const enum SIDEBAR_MENU {
    DASHBOARD = 1,
    TEST
}

export const enum ApiStatus {
    DEFAULT = 1,
    LOADING,
    SUCCESS,
    ERROR
}

export const enum SORT_DIRECTION {
    ASC,
    DESC
}

export const enum TOAST_VARIANT {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success'
}

export const enum SIZE_VARIANT {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export const enum AVATAR_STATUS {
    ONLINE = 'online',
    NO_DISTURB = 'no-disturb',
    NO_AVAILABLE = 'no-available',
    ABSENT = 'absent',
    WARNING = 'warning',
    NONE = ''
}

export const enum CHART_TYPE {
    LINE = 'line',
    SPLINE = 'spline',
    BAR = 'bar',
    AREA = 'area',
    SCATTER = 'scatter',
    COLUMN = 'column',
    PIE = 'pie',
    MAP = 'map'
}

export const enum INVESTOR_MODULE_ROUTES {
    OVERVIEW = 'overview',
    SCENARIO_LIBRARY = 'scenario-library',
    SCENARIO_OUTCOMES = 'scenario-outcomes',
    PROJECT_PERFORMANCE = 'project-performance',
    COMPARISON_CAPABLITIES = 'comparison-outcomes',
    WHAT_IF_SCENARIOS = 'what-if-scenario'
}

export const enum SCENARIO_OUTCOMES_ROUTES {
    GENERATORS_BY_TECHNOLOGY = 'generators-by-technology',
    PRICES = 'prices',
    INTERCONNECTOR_FLOWS = 'interconnector-flows',
    NETWORK = 'network',
    TARGET_FULFILMENT = 'emissions-policy-targets'
}

export const enum COMPARISON_CAPABILITIES_ROUTES {
    MARKET_OUTCOMES = 'market-outcomes',
    PROJECT_OUTCOMES = 'project-outcomes'
}

export const enum QueryKeyEnum {
    'Query' = 'q'
}

export const enum TAB_TYPE_COLOR {
    INPUT_COLOR = '#168736',
    RESULT_COLOR = '#4EBEEB'
}

export const enum EmptyChartType {
    noData,
    error,
    noScenarioData
}

// Filter Enum should match with the filter reducer's initial state
export const enum FilterEnum {
    SCENARIO = 'scenario',
    MULTI_SCENARIO = 'multiscenario',
    TECHNOLOGY = 'technology',
    REGIONS = 'region',
    INTERCONNECTOR = 'interconnector',
    SINGLE_TECHNOLOGY = 'singleTechnology',
    SINGLE_REGIONS = 'singleRegions',
    PROJECT = 'project',
    COMPARISON_CAPABILITIES = 'capabilitiesRadioToggleSelect',
    TRANSMISSION_LINE = 'transmissionLine'
}
// To do
export const enum FilterTypeEnum {
    SINGLE = 'single',
    MULTI = 'multi'
}

export const enum CONSTRAINT_COLORS {
    GREEN = '#168736',
    LIGHT_YELLOW = '#B9AA20',
    MID_YELLOW = '#968C30',
    DARK_YELLOW = '#6E6727',
    ORANGE = '#FF9831',
    ORANGE_RED = '#F76900',
    RED = '#FF4236',
    TRANSPARENT = 'transparent',
    WHITE = '#FFFFFF'
}
export const enum DropdownName {
    GLOBAL_SCENARIO_FILTER = 'globalScenarioFilter',
    GLOBAL_PROJECT_FILTER = 'globalProjectFilter',
    WHAT_IF_PROJECT_FILTER = 'whatIfProjectFilter'
}

export const enum VOLTAGE_COLORS {
    YELLOW = '#FEFE0A',
    ORANGE = '#F0AA02',
    PINK = '#D72183',
    BLUE = '#2904FF',
    RED = '#EB260F',
    BROWN = '#754207'
}

export const enum SIMULATION_LABELS {
    referenceCase = 'referenceCase',
    simulationTitle = 'simulationTitle',
    publishedDate = 'publishedDate',
    simulationDescription = 'simulationDescription'
}

export const enum SCENARIO_LIBRARY_ROUTES {
    AVAILABLE_SCENARIOS = 'available-scenarios',
    PERFORMANCE_SCENARIOS = 'perform-scenario-sensitivity',
    CHART_REFERENCE_GUIDE = 'chart-reference-guide'
}

export enum POLICY_TYPE {
    PRIVACY_NOTICE = 1,
    TERMS_OF_USE = 2
}

export enum CHART_TITLE {
    INSTALLED_CAPCITY = 'Installed Capacity',
    AVERAGE_ANNUAL_PRICE = 'Annual Average Price (Time Weighted)',
    TIME_OF_DAY_PER_YEAR = 'Annual Average Price (by Time of Day)',
    AVERAGE_ANUAL_INTERCONNECTOR_FLOW = 'Annual Average Interconnector Flow',
    MODELLED_NETWORK = 'Modelled Network',
    CONGESTION_OUTCOMES = 'Congestion Outcomes',
    CONSTRAINT_BINDING = 'Constraint binding heatmap',
    TOTAL_EMISSIONS = 'Total Emissions',
    UNSERVED_ENERGY = 'Unserved Energy',
    GENERATION_PER_FINANCIAL_YEAR = 'Dispatch and Availability',
    TIME_WEIGHTED_AND_DISPATCH_WEIGHTED_PRICE = 'Time Weighted and Dispatch Weighted Price',
    REVENUE = 'Gross Market Revenue',
    MLF_OUTCOMES_COMPARISON = 'Marginal Loss Factor Trend',
    MLF_VS_GENERATION_DISPATCHED = 'MLF vs Dispatch',
    INSTALLED_CAPACITY_DIFFERENCE = 'Installed Capacity Difference',
    ANNUAL_ACHIEVED_GENERATION = 'Annual Dispatch',
    AVERAGE_GENERATION_BY_TOD = 'Average Dispatch by Time of Day',
    DWP_COMPARISON = 'Annual Average DWP',
    ANNUAL_REVENUE = 'Annual Revenue',
    YEAR_ON_YEAR_CAPACITY_CHANGE = 'Year On Year Capacity Change',
    GENERATION_MIX_AS_GENERATED = 'Generation Mix As-Generated',
    AVERAGE_TIME_OF_DAY = 'Average Time Of Day Interconnector Flow',
    GENERATION_MIX_DIFFERENCE = 'Generation Mix As-Generated Difference',
    AVERAGE_ANNUAL_PRICE_FLOW = 'Annual Average Price',
    TOD_AVARAGE_PRICE = 'Time of Day Average Price'
}
