import React from 'react';
import styled from 'styled-components';
import { MotifProgressIndicatorStep } from '@ey-xd/motif-react';

type ProgressIndicatorProps = {
    className?: string;
    heading: string;
    onClick: () => void;
    status: string;
    icons?: {
        active?: React.ReactNode;
        complete?: React.ReactNode;
        current?: React.ReactNode;
    };
};

const ProgressIndicatorStep = styled(
    ({ className = '', heading, onClick, icons = {}, ...props }: ProgressIndicatorProps) => (
        <MotifProgressIndicatorStep
            className={className}
            heading={heading}
            onClick={onClick}
            icons={icons}
            {...props}
        />
    )
)``;
export default ProgressIndicatorStep;
