import { FilterEnum } from 'core/base/enum';
import { IFilterGeneric, IFilterState, IPreSelectedFilters } from 'core/base/type';
import { getMotifColor } from '.';

const Q_QUERY_KEY = 'q';

export const buildFilteredQueryParams = (
    filterState: IFilterState,
    searchString: string,
    currentFilters: string[]
): string => {
    const URLParams: URLSearchParams = new URLSearchParams(searchString);
    const qQueryValue: string = URLParams.get(Q_QUERY_KEY) || '';
    let updatedObje: IPreSelectedFilters = {};

    if (filterState.scenario.selected !== '' && currentFilters.includes(FilterEnum.SCENARIO)) {
        updatedObje = {
            ...updatedObje,
            scenario: filterState.scenario.selected
        };
    }
    if (
        filterState.technology.selected.length > 0 &&
        currentFilters.includes(FilterEnum.TECHNOLOGY)
    ) {
        updatedObje = {
            ...updatedObje,
            technology: filterState.technology.selected
        };
    }
    if (filterState.region.selected.length > 0 && currentFilters.includes(FilterEnum.REGIONS)) {
        updatedObje = {
            ...updatedObje,
            region: filterState.region.selected
        };
    }
    if (
        filterState.interconnector.selected.length > 0 &&
        currentFilters.includes(FilterEnum.INTERCONNECTOR)
    ) {
        updatedObje = {
            ...updatedObje,
            interconnector: filterState.interconnector.selected
        };
    }
    if (
        filterState.singleTechnology.selected.length > 0 &&
        currentFilters.includes(FilterEnum.SINGLE_TECHNOLOGY)
    ) {
        updatedObje = {
            ...updatedObje,
            singleTechnology: filterState.singleTechnology.selected
        };
    }
    if (
        filterState.singleRegions.selected.length > 0 &&
        currentFilters.includes(FilterEnum.SINGLE_REGIONS)
    ) {
        updatedObje = {
            ...updatedObje,
            singleRegions: filterState.singleRegions.selected
        };
    }
    if (
        filterState.multiscenario.selected.length > 0 &&
        currentFilters.includes(FilterEnum.MULTI_SCENARIO)
    ) {
        updatedObje = {
            ...updatedObje,
            multiscenario: filterState.multiscenario.selected
        };
    }

    const jsonObj = qQueryValue ? JSON.parse(qQueryValue) : {};
    const returObject: any = {
        ...jsonObj,
        ...updatedObje
    };

    const queryString = serializeQueryString(returObject);
    return queryString;
};

export const serializeQueryString = (obj: any): string => {
    return JSON.stringify(obj);
};

export const createNewUrl = (searchPath: string, qQueryValue: string): string => {
    const url = `${searchPath}?${Q_QUERY_KEY}=${qQueryValue}`;
    return `${url}`;
};

export const removeHashFromURL = () => {
    let searchUrl = window.location.search;
    const urlParameter = window.location;
    if (
        (urlParameter.hash !== '' || urlParameter.hash !== null) &&
        !urlParameter.hash.includes('id_token')
    ) {
        searchUrl = urlParameter.search + urlParameter.hash;
        return searchUrl;
    } else {
        return searchUrl;
    }
};

export const createFiltersObject = (
    actionObj: Array<IFilterGeneric>
): { updatedData: Array<IFilterGeneric>; selectedData: Array<string> } => {
    const updatedData: Array<IFilterGeneric> = [];
    const selectedData: Array<string> = [];
    if (actionObj) {
        actionObj?.map((values, index) => {
            updatedData?.push({
                name: values?.name,
                id: String(values?.id),
                color: getMotifColor(index)
            });
            selectedData?.push(String(values?.id));
        });
    }

    return { updatedData, selectedData };
};

export const createTechFilter = (payload: any[], selectedRegionData: Array<string>) => {
    const updatedData: Array<IFilterGeneric> = [];
    const selectedData: Array<string> = [];
    let count = 0;
    if (payload) {
        payload?.map((values) => {
            if (selectedRegionData?.includes(String(values?.id))) {
                values?.technology?.map((tech: { name: string; id: string }) => {
                    if (!selectedData.includes(String(tech?.id))) {
                        updatedData?.push({
                            name: tech?.name,
                            id: String(tech?.id),
                            color: getMotifColor(count++)
                        });
                        selectedData?.push(String(tech?.id));
                    }
                });
            }
        });
    }

    return { updatedData, selectedData };
};

export const createSelectedScenarioObject = (actionObj: Array<IFilterGeneric>): Array<string> => {
    const updatedSelectedScenarioData: Array<string> = [];
    createSelectedScenarioSubObject(actionObj);
    function createSelectedScenarioSubObject(actionObj: Array<IFilterGeneric>) {
        if (actionObj) {
            actionObj?.forEach((values) => {
                updatedSelectedScenarioData?.push(String(values?.id));
                if (values?.data && values?.data?.length > 0) {
                    createSelectedScenarioSubObject(values?.data);
                }
            });
        }
    }
    return updatedSelectedScenarioData;
};
