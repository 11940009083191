import React from 'react';
import { StyledPolicyContent } from './PolicyModalBody.style';
import { sanitizeHtml } from 'common/utils';

interface IOwnProps {
    content: string;
}
const PolicyContent: React.FC<IOwnProps> = ({ content }) => {
    return (
        <StyledPolicyContent>
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
        </StyledPolicyContent>
    );
};

export default React.memo(PolicyContent);
